<template>
  <div>
    <div v-if="loading">
      <Header />
      <img src="@/assets/img/loading.svg" class="loading" />
    </div>
    <div v-else>
      <div class="toggle-full-form">
        <toggle-button
          color="#1c3a53"
          :value="showFullForm"
          :sync="true"
          @change="changeFullForm"
        />Show full form
      </div>
      <Header @handle-save="saveForm(false)" @handle-submit="submitForm" />
      <form-wizard
        id="ascForm"
        ref="ascForm"
        class="asc-form-wizard full-width"
        color="#1c3a53"
        title
        subtitle
        :startIndex="0"
        @on-complete="submitForm"
      >
        <tab-content title="Coordinator" :before-change="validateAppuser">
          <label>
            Prefix
            <span class="danger">*</span>
          </label>
          <select
            v-validate="'required'"
            name="prefix"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('prefix') }"
            v-model="appUser.prefix"
          >
            <option
              v-for="option in dropdownOptions.prefixOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <span class="danger">{{ errors.first("prefix") }}</span>

          <label>
            First name
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required'"
            name="first name"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('first name') }"
            v-model="appUser.firstName"
          />
          <span class="danger">{{ errors.first("first name") }}</span>

          <label>
            Last name
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required'"
            name="last name"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('last name') }"
            v-model="appUser.lastName"
          />
          <span class="danger">{{ errors.first("last name") }}</span>

          <label>
            Company/hospital name
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required'"
            name="company/hospital name"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('company/hospital name') }"
            v-model="appUser.company"
          />
          <span class="danger">{{
            errors.first("company/hospital name")
          }}</span>

          <label>
            Division/department
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required'"
            name="division/department"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('division/department') }"
            v-model="appUser.department"
          />
          <span class="danger">{{ errors.first("division/department") }}</span>

          <label>
            E-mail address
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required|email'"
            name="e-mail"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('e-mail') }"
            v-model="appUser.email"
          />
          <span class="danger">{{ errors.first("e-mail") }}</span>

          <label>Additional e-mail address</label>
          <input
            v-validate="'email'"
            name="additional e-mail"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('additional e-mail') }"
            v-model="appUser.emailAdditional"
          />
          <span class="input-sub"
            >E-mail address that will be included in cc of all e-mails</span
          >
          <span class="danger">{{ errors.first("additional e-mail") }}</span>

          <label>
            Mobile number
            <span class="danger">*</span>
          </label>
          <input
            v-validate="{
              required: true,
              min: 10,
              regex:
                /^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/,
            }"
            name="mobile number"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('mobile number') }"
            v-model="appUser.phone"
          />
          <span class="input-sub"
            >Please include country dialing code, e.g. +31 612345678</span
          >
          <span class="danger">{{ errors.first("mobile number") }}</span>

          <label>
            Billing details
            <span class="danger">*</span>
          </label>
          <textarea
            v-validate="'required'"
            name="billing details"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('billing details') }"
            rows="6"
            v-model="appUser.billing"
          ></textarea>
          <span class="input-sub">Address, VAT and Chamber of Commerce</span>
          <span class="danger">{{ errors.first("billing details") }}</span>
        </tab-content>

        <tab-content title="Event" :before-change="validateEvent">
          <label>
            Event/course title
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required'"
            name="event/course title"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('event/course title') }"
            v-model="events.title"
          />
          <span class="danger">{{ errors.first("event/course title") }}</span>

          <label>
            Start date of event/course
            <span class="danger">*</span>
          </label>
          <datepicker
            v-model="events.eventDate"
            input-class="asc-form-input"
            v-validate="'required'"
            name="start date"
            :class="{ 'is-danger': errors.has('start date') }"
            :disabled-dates="{ to: new Date() }"
            @closed="changeEventStartDate"
          ></datepicker>
          <span class="input-sub">Can be a preliminary date</span>
          <span class="danger">{{ errors.first("start date") }}</span>

          <div v-if="showFullForm">
            <label>
              Discipline
              <span class="danger">*</span>
            </label>
            <input
              v-validate="'required'"
              name="discipline"
              type="text"
              class="asc-form-input"
              :class="{ 'is-danger': errors.has('discipline') }"
              v-model="events.speciality"
            />
            <span class="input-sub"
              >i.e. orthopedics, urology, gynaecology, etc.</span
            >
            <span class="danger">{{ errors.first("discipline") }}</span>
          </div>

          <label>
            Length of event
            <span class="danger">*</span>
          </label>
          <select
            v-validate="'required'"
            name="event length"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('event length') }"
            v-model="events.numberOfDays"
            @change="changeEventLength"
          >
            <option
              v-for="option in dropdownOptions.eventLengthOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <span class="input-sub"
            >Number of days at the Amsterdam Skills Centre</span
          >
          <span class="danger">{{ errors.first("event length") }}</span>

          <label>
            Wet lab required for event?
            <span class="danger">*</span>
          </label>
          <select
            v-validate="'required'"
            name="wet lab required"
            type="text"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('wet lab required') }"
            v-model="events.wetlabRequired"
            @change="resetTabIndex"
          >
            <option
              v-for="option in dropdownOptions.eventWetlabRequiredOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <span class="danger">{{ errors.first("wet lab required") }}</span>

          <label>
            Event description/course objectives
            <span class="danger">*</span>
          </label>
          <textarea
            v-validate="'required|min:180'"
            name="event description"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('event description') }"
            rows="6"
            v-model="events.description"
          ></textarea>
          <span class="input-sub"
            >Minimal number of 180 characters, please describe in layman
            terms.</span
          >
          <span v-if="errors.has('event description')" class="danger"
            >{{ errors.first("event description") }} (is now
            {{ events.description.length }} characters)</span
          >

          <div v-if="showFullForm">
            <label>
              Are CME points (or other accreditation) rewarded?
              <span class="danger">*</span>
            </label>
            <select
              v-validate="'required'"
              name="CME points"
              type="text"
              class="asc-form-input"
              :class="{ 'is-danger': errors.has('CME points') }"
              v-model="events.CMErewarded"
            >
              <option
                v-for="option in dropdownOptions.CMErewardedOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <span class="danger">{{ errors.first("CME points") }}</span>
          </div>

          <div v-if="showFullForm">
            <label>
              Is the event sponsored?
              <span class="danger">*</span>
            </label>
            <select
              v-validate="'required'"
              name="event sponsored"
              type="text"
              class="asc-form-input"
              :class="{ 'is-danger': errors.has('event sponsored') }"
              v-model="events.sponsored"
            >
              <option
                v-for="option in dropdownOptions.sponsoredOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <span class="danger">{{ errors.first("event sponsored") }}</span>
          </div>

          <div v-if="showFullForm">
            <div v-if="events.sponsored && events.sponsored !== '0'">
              <label>
                Sponsor name/display required?
                <span class="danger">*</span>
              </label>
              <textarea
                v-validate="'required'"
                name="sponsor name"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('sponsor name') }"
                rows="4"
                v-model="events.sponsor"
              ></textarea>
              <span class="input-sub"
                >If yes, by who? Is a display area required and would you like
                to advertise on the screen above the welcome desk and/or in the
                atrium?</span
              >
              <span class="danger">{{ errors.first("sponsor name") }}</span>
            </div>
          </div>
        </tab-content>

        <tab-content title="Attendees" :before-change="validateAttendees">
          <div v-if="showFullForm">
            <!--label>
              Upload attendees list (preferably in Excel)
              <span class="danger">*</span>
            </label>
            <vue-dropzone
              ref="attendeesDropzone"
              id="attendeesDropzone"
              :options="attendeesDropzoneOptions"
              :use-custom-slot="true"
              @vdropzone-sending="sendFileDropzone"
              @vdropzone-removed-file="removeFileAttendees"
              >Drop your file or click here</vue-dropzone
            >
            <span class="input-sub"
              >To comply with the Dutch Safety Regulations the ASC requires to have a list of all visitors /
              participants including attendees, faculty and guests of the course. In order for the ASC to be compliant
              with laws and regulations could you please provide us with the full attendee list. Preferably in Excel,
              other accepted files are csv, pdf or Word.</span
            -->

            <label>
              Describe the target audience
              <span class="danger">*</span>
            </label>
            <input
              v-validate="'required'"
              name="target audience description"
              type="text"
              class="asc-form-input"
              :class="{
                'is-danger': errors.has('target audience description'),
              }"
              v-model="attendees.targetAudience"
            />
            <span class="danger">{{
              errors.first("target audience description")
            }}</span>
          </div>

          <label>
            Number of participants/delegates
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required|integer'"
            name="number of participants"
            type="number"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('number of participants') }"
            v-model="attendees.numberOfParticipants"
          />
          <span class="input-sub"
            >Excluding own representatives and faculty</span
          >
          <span class="danger">{{
            errors.first("number of participants")
          }}</span>

          <label>
            Number of supervisors/faculty
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required|integer'"
            name="number of supervisors"
            type="number"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('number of supervisors') }"
            v-model="attendees.numberOfSupervisors"
          />
          <span class="danger">{{
            errors.first("number of supervisors")
          }}</span>

          <label>
            Number of own representatives
            <span class="danger">*</span>
          </label>
          <input
            v-validate="'required|integer'"
            name="number of representatives"
            type="number"
            class="asc-form-input"
            :class="{ 'is-danger': errors.has('number of representatives') }"
            v-model="attendees.numberOfRepresentatives"
          />
          <span class="danger">{{
            errors.first("number of representatives")
          }}</span>
          <label>
            Upload list of participants
            <span class="danger" v-if="showFullForm">*</span>
          </label>
          <vue-dropzone
            ref="attendeesDropzone"
            id="attendeesDropzone"
            :options="attendeeListDropzoneOptions"
            :use-custom-slot="true"
            @vdropzone-sending="sendFileDropzone"
            @vdropzone-removed-file="removeFileAttendees"
            >Drop your file or click here</vue-dropzone
          >
          <span class="input-sub"
            >Please upload a list of participants as seen in the
            <a href="/data/template_participants.xlsx">example .xlsx</a></span
          >

          <span
            class="danger"
            style="margin-top: 12px"
            v-if="attendeeUploadError"
          >
            This field is required
          </span>

          <br />
          <br />
        </tab-content>

        <tab-content title="Event agenda" :before-change="validateAgenda">
          <label>
            Upload (draft) agenda
            <span class="danger" v-if="showFullForm">*</span>
          </label>
          <vue-dropzone
            ref="agendaDropzone"
            id="agendaDropzone"
            :options="agendaDropzoneOptions"
            :use-custom-slot="true"
            @vdropzone-sending="sendFileDropzone"
            @vdropzone-removed-file="removeFileAgenda"
            >Drop your file or click here</vue-dropzone
          >
          <span class="input-sub"
            >Please upload an agenda with event details (Excel, csv, pdf or word
            file)</span
          >
          <span
            class="danger"
            style="margin-top: 12px"
            v-if="agendaUploadError"
          >
            This field is required
          </span>

          <br />
          <br />
          <label
            >The ASC is open from 08:00-17:00. Use of the centre beyond these
            hours is only possible in consultation with the ASC and will be
            charged extra.</label
          >
          <label>Enter time schedules</label>
          <vue-tabs v-model="timeSchedulesTabIndex" v-if="agendas.length">
            <v-tab
              v-for="(agenda, index) in agendas"
              :key="index"
              :title="'Day ' + (index + 1)"
              :icon="agendaDangerTab(index)"
            >
              <label v-if="agenda.eventDayDate">
                Time schedule for
                {{ agenda.eventDayDate | moment("dddd DD MMMM YYYY") }}
                <span class="danger">*</span>
              </label>
              <button
                v-if="index > 0"
                class="asc-btn"
                @click="copyAgendaTimes(index)"
              >
                Copy times from day {{ index }}
              </button>

              <label>Start time</label>
              <vue-timepicker
                v-validate="'required'"
                input-class="skip-error-style"
                :name="'start time day ' + (index + 1)"
                :class="[
                  { 'is-danger': errors.has('start time day ' + (index + 1)) },
                ]"
                :minute-interval="15"
                v-model="agenda.startTime"
              ></vue-timepicker>
              <div class="danger">
                {{ errors.first("start time day " + (index + 1)) }}
              </div>

              <label>End time</label>
              <vue-timepicker
                v-validate="'required'"
                input-class="skip-error-style"
                :name="'end time day ' + (index + 1)"
                :class="[
                  { 'is-danger': errors.has('end time day ' + (index + 1)) },
                ]"
                :minute-interval="15"
                v-model="agenda.endTime"
              ></vue-timepicker>
              <div class="danger">
                {{ errors.first("end time day " + (index + 1)) }}
              </div>

              <div v-if="showFullForm">
                <label>Time (your) staff enters the facility</label>
                <vue-timepicker
                  v-validate="'required'"
                  input-class="skip-error-style"
                  :name="'staff arrival time day ' + (index + 1)"
                  :class="[
                    {
                      'is-danger': errors.has(
                        'staff arrival time day ' + (index + 1)
                      ),
                    },
                  ]"
                  :minute-interval="15"
                  v-model="agenda.staffArriveTime"
                ></vue-timepicker>
                <div class="danger">
                  {{ errors.first("staff arrival time day " + (index + 1)) }}
                </div>

                <label>Time (your) staff leaves the facility</label>
                <vue-timepicker
                  v-validate="'required'"
                  input-class="skip-error-style"
                  :name="'staff departure time day ' + (index + 1)"
                  :class="[
                    {
                      'is-danger': errors.has(
                        'staff departure time day ' + (index + 1)
                      ),
                    },
                  ]"
                  :minute-interval="15"
                  v-model="agenda.staffLeaveTime"
                ></vue-timepicker>
                <div class="danger">
                  {{ errors.first("staff departure time day " + (index + 1)) }}
                </div>
              </div>
            </v-tab>
          </vue-tabs>
        </tab-content>

        <tab-content
          v-if="events.wetlabRequired.toLowerCase().trim() !== 'no'"
          title="Wet lab agenda"
          :before-change="validateWetLabAgenda"
        >
          <div v-if="events.wetlabRequired.toLowerCase().trim() == 'yes'">
            <label
              >Our wet lab is open between 09:00-16:00. In case you wish to have
              different wet lab timings, please consult with our staff for
              exceptions to the fixed opening hours.</label
            >
            <br />
            <br />
            <vue-tabs v-model="wetLabTabIndex">
              <v-tab
                v-for="(wetlabAgenda, index) in wetlabAgendas"
                :key="index"
                :title="'Day ' + (index + 1)"
                :icon="wetLabAgendaDangerTab(index)"
              >
                <label>
                  <toggle-button
                    color="#1c3a53"
                    v-model="wetlabAgenda.wetLabNeededThisDay"
                    :sync="true"
                  />
                  <span v-if="agendas.length"
                    >Wet lab is needed for
                    {{
                      agendas[index].eventDayDate | moment("dddd DD MMMM YYYY")
                    }}</span
                  >
                  <span class="danger">*</span>
                </label>
                <br />
                <div v-if="wetlabAgenda.wetLabNeededThisDay">
                  <button
                    v-if="showWetLabAgendaCopyTimesBtn(index)"
                    class="asc-btn"
                    @click="copyWetLabAgendaTimes(index)"
                  >
                    Copy times from day {{ index }}
                  </button>

                  <label
                    >Start time in wet lab Test (for
                    participants/faculty)</label
                  >
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'start time in wet lab day ' + (index + 1)"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'start time in wet lab day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    :hour-range="[9, 10, 11, 12, 13, 14, 15, 16]"
                    v-model="wetlabAgenda.startTimePreference"
                  ></vue-timepicker>
                  <div class="danger">
                    {{
                      errors.first("start time in wet lab day " + (index + 1))
                    }}
                  </div>

                  <label>End time in wet lab (for participants/faculty)</label>
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'end time in wet lab day ' + (index + 1)"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'end time in wet lab day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    :hour-range="[9, 10, 11, 12, 13, 14, 15, 16]"
                    v-model="wetlabAgenda.endTimePreference"
                  ></vue-timepicker>
                  <div class="danger">
                    {{ errors.first("end time in wet lab day " + (index + 1)) }}
                  </div>

                  <label>Preferred time slot/moment to set up?</label>
                  <select
                    v-validate="'required'"
                    :name="
                      'preferred setup time slot/moment day ' + (index + 1)
                    "
                    class="asc-form-input"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'preferred setup time slot/moment day ' + (index + 1)
                        ),
                      },
                    ]"
                    v-model="wetlabAgenda.preferredSetupTimeslotId"
                  >
                    <option
                      v-for="option in dropdownOptions.preferredSetupTimeslotIdOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                  <div class="danger">
                    {{
                      errors.first(
                        "preferred setup time slot/moment day " + (index + 1)
                      )
                    }}
                  </div>

                  <label>Amount of set up time required?</label>
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'setup time day ' + (index + 1)"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'setup time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="wetlabAgenda.setupTime"
                  ></vue-timepicker>
                  <div class="danger">
                    {{ errors.first("setup time day " + (index + 1)) }}
                  </div>

                  <label
                    >Amount of breakdown time required after the event has
                    ended?</label
                  >
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'breakdown time day ' + (index + 1)"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'breakdown time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="wetlabAgenda.breakupTime"
                  ></vue-timepicker>
                  <div class="danger">
                    {{ errors.first("breakdown time day " + (index + 1)) }}
                  </div>
                </div>
              </v-tab>
            </vue-tabs>
          </div>
          <div v-else>
            <label>
              Wet lab required for event?
              <span class="danger">*</span>
            </label>
            <select
              type="text"
              class="asc-form-input"
              v-model="events.wetlabRequired"
              @change="resetTabIndex"
            >
              <option
                v-for="option in dropdownOptions.eventWetlabRequiredOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </tab-content>

        <tab-content
          v-if="wetLabSetupTabNeeded"
          title="Wet lab setup"
          :before-change="validateWetLabSetup"
        >
          <!-- start -->

          <vue-tabs @tab-change="wetlabSetupTabChange">
            <v-tab
              v-for="(wetlabSetup, index) in wetlabSetups"
              :key="index"
              :title="'Day ' + (index + 1)"
              :icon="wetlabSetupDangerTab(index)"
            >
              <div v-if="events.wetlabRequired.toLowerCase().trim() == 'yes'">
                <label>
                  Number of operating stations required?
                  <span class="danger">*</span>
                </label>
                <select
                  v-validate="'required|integer'"
                  :name="'number of operating stations' + index"
                  class="asc-form-input"
                  :class="{
                    'is-danger': errors.has('number of operating stations'),
                  }"
                  v-model="wetlabSetup.numberOfStations"
                >
                  <option :value="0">None</option>
                  <option v-for="i in 12" :key="i" :value="i">{{ i }}</option>
                </select>
                <span class="danger">{{
                  errors.first("number of operating stations")
                }}</span>

                <div v-if="showFullForm">
                  <label>
                    Disposables
                    <br />
                    <small>
                      All basic disposables are available at the Amsterdam
                      Skills Centre.
                      <br />- Basic gloves <br />- Nurse cap <br />- Surgical
                      mask <br />- Fluidshield Mask <br />- Shoe Covers <br />-
                      Gown <br />- Gauze <br />- Skin marker
                    </small>
                  </label>

                  <label>Extra disposables</label>
                  <input
                    type="text"
                    class="asc-form-input"
                    v-model="wetlabSetup.disposables"
                  />

                  <label>Miscellaneous available on request</label>
                  <div
                    class="asc-switch"
                    v-for="checkbox in dropdownOptions.wetlabMiscellaneousCheckboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      v-model="checkbox.value"
                      @change="fillWetlabSetupMiscellaneousArray(index)"
                      :sync="true"
                    />
                    {{ checkbox.text }}
                  </div>

                  <label>
                    Instruments
                    <br />
                    <small>
                      The list of basic instruments available at the ASC can be
                      found here
                      <a
                        href="https://academy.asc.amsterdam/blog/index.php?entryid=1"
                        target="_blank"
                        >here >></a
                      >
                    </small>
                  </label>

                  <label>Other instruments</label>
                  <span class="input-sub"
                    >Extra instruments are available on request or in
                    consultation with the ASC. Please indicate here which extra
                    instruments would be required.</span
                  >
                  <textarea
                    class="asc-form-input"
                    rows="6"
                    v-model="wetlabSetup.instruments"
                  ></textarea>

                  <label>External/own instruments to be delivered?</label>
                  <input
                    type="text"
                    class="asc-form-input"
                    v-model="wetlabSetup.externalInstruments"
                  />
                  <span class="input-sub"
                    >Elaborate on the amount and types of
                    instruments/equipment.</span
                  >

                  <label>
                    Washing/sterilisation wishes?
                    <span class="danger">*</span>
                  </label>
                  <select
                    v-validate="'required'"
                    :name="'washing/sterilisation wishes' + index"
                    class="asc-form-input"
                    :class="[
                      {
                        'is-danger': errors.has('washing/sterilisation wishes'),
                      },
                    ]"
                    v-model="wetlabSetup.washingSterilisation"
                  >
                    <option
                      v-for="option in dropdownOptions.washingSterilisationOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                  <span class="input-sub"
                    >Standard washing and sterilisation time is 2-3 working
                    days.</span
                  >
                  <span class="danger">{{
                    errors.first("washing/sterilisation wishes")
                  }}</span>

                  <div v-if="wetlabSetup.washingSterilisation == 1">
                    <label>
                      Number of kits to be washed
                      <span class="danger">*</span>
                    </label>
                    <input
                      v-validate="'required|integer'"
                      :name="'number of kits to be washed' + index"
                      type="number"
                      class="asc-form-input"
                      :class="{
                        'is-danger': errors.has('number of kits to be washed'),
                      }"
                      v-model="wetlabSetup.numberOfWashedKits"
                    />
                    <span class="danger">{{
                      errors.first("number of kits to be washed")
                    }}</span>

                    <label>
                      Number of kits to be sterilized
                      <span class="danger">*</span>
                    </label>
                    <input
                      v-validate="'required|integer'"
                      :name="'number of kits to be sterilized' + index"
                      type="number"
                      class="asc-form-input"
                      :class="{
                        'is-danger': errors.has(
                          'number of kits to be sterilized'
                        ),
                      }"
                      v-model="wetlabSetup.numberOfSterilisedKits"
                    />
                    <span class="danger">{{
                      errors.first("number of kits to be sterilized")
                    }}</span>
                  </div>
                </div>

                <label>Number of C-arms required?</label>
                <select class="asc-form-input" v-model="wetlabSetup.CArms">
                  <option
                    v-for="option in dropdownOptions.CArmsOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <span class="input-sub"
                  >Please note, currently no roadmapping is possible with our
                  C-arms.</span
                >
                <div v-if="wetlabSetup.CArms >= 1">
                  <label>Are external C-arms required?</label>
                  <div class="asc-switch">
                    <toggle-button
                      color="#1c3a53"
                      :value="externalCarmsNeeded"
                      @change="changeExternalCarmsNeeded"
                      :sync="true"
                    />
                    {{ externalCarmsNeeded ? "Yes" : "No" }}
                  </div>
                  <div v-show="externalCarmsNeeded">
                    <label
                      >Will you bring your own/specialised c-arms to the ASC for
                      this course? If so, please elaborate below and supply the
                      technical details.</label
                    >
                    <textarea
                      class="asc-form-input"
                      rows="6"
                      v-model="wetlabSetup.CArmsExternal"
                    ></textarea>
                    <label>
                      Use of external C-arms for days
                      <span class="danger">*</span>
                    </label>
                    <select
                      v-validate="'required'"
                      name="external C-arms for days"
                      class="asc-form-input"
                      :class="[
                        { 'is-danger': errors.has('external C-arms for days') },
                      ]"
                      v-model="wetlabSetup.CArmsDays"
                    >
                      <option :value="0">0</option>
                      <option
                        v-for="i in events.numberOfDays"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                    <span class="danger">{{
                      errors.first("external C-arms for days")
                    }}</span>
                  </div>
                </div>
                <label>
                  Please indicate the number of people in need of protective
                  Lead-gowns during radiation in the wet lab, as these are
                  limited available on property
                  <span class="danger">*</span>
                </label>
                <input
                  v-validate="'required|integer'"
                  :name="'number of ledgowns' + index"
                  type="number"
                  class="asc-form-input"
                  :class="{ 'is-danger': errors.has('number of ledgowns') }"
                  v-model="wetlabSetup.ledGowns"
                />

                <label>
                  Are specimens required?
                  <span class="danger">*</span>
                </label>
                <select
                  v-validate="'required'"
                  :name="'specimens required' + index"
                  class="asc-form-input"
                  :class="[{ 'is-danger': errors.has('specimens required') }]"
                  v-model="wetlabSetup.specimens"
                >
                  <option
                    v-for="option in dropdownOptions.specimensOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <span class="danger">{{
                  errors.first("specimens required")
                }}</span>
              </div>
              <div v-else>
                <label>
                  Wet lab required for event?
                  <span class="danger">*</span>
                </label>
                <select
                  type="text"
                  class="asc-form-input"
                  v-model="events.wetlabRequired"
                  @change="changeWetlabReqOnTab('wet lab setup')"
                >
                  <option
                    v-for="option in dropdownOptions.eventWetlabRequiredOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </v-tab>
          </vue-tabs>

          <!-- end -->
        </tab-content>

        <tab-content
          v-if="
            events.wetlabRequired.toLowerCase().trim() !== 'no' &&
            wetlabSetups[0].specimens.toLowerCase().trim() !== 'no'
          "
          title="Specimens"
          :before-change="validateSpecimens"
        >
          <div v-if="events.wetlabRequired.toLowerCase().trim() == 'yes'">
            <div v-if="wetlabSetups[0].specimens.toLowerCase().trim() == 'yes'">
              <label>
                The ASC uses two types of specimens:
                <small>
                  <br />- Fresh Frozen (FF) <br />- Fix 4 Life (F4L) embalmed
                  specimen
                </small>
              </label>
              <span class="input-sub specimen-sub"
                >The type of specimen used is considered based on type of
                procedure, ethical use of the body, extra requirements (i.e. CT
                scanning), and availability of specimens. Based on the
                availability of specimens, there is sometimes a need to be
                flexible between FF and F4L.</span
              >

              <label>
                Preferred type of specimen
                <span class="danger">*</span>
              </label>
              <select
                v-validate="'required'"
                name="type of specimen"
                class="asc-form-input"
                :class="[{ 'is-danger': errors.has('type of specimen') }]"
                v-model="specimens.specimenTypeId"
              >
                <option
                  v-for="option in dropdownOptions.specimenTypeIdOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
              <span class="danger">{{ errors.first("type of specimen") }}</span>

              <div v-if="specimens.specimenTypeId.toString() == '1'">
                <label>
                  Fix 4 Life anatomical region description
                  <span class="danger">*</span>
                </label>
                <textarea
                  v-validate="'required'"
                  name="F4L anatomical region description"
                  class="asc-form-input"
                  :class="{
                    'is-danger': errors.has(
                      'F4L anatomical region description'
                    ),
                  }"
                  rows="6"
                  v-model="specimens.anatomicalRegionF4L"
                ></textarea>
                <span class="input-sub"
                  >Describe using accurate anatomical terminology.</span
                >
                <span class="danger">{{
                  errors.first("F4L anatomical region description")
                }}</span>
              </div>
              <div v-if="specimens.specimenTypeId.toString() == '2'">
                <label>Fresh Frozen anatomical region</label>
                <div
                  class="asc-switch"
                  v-for="checkbox in dropdownOptions.specimensAnatomicalRegionFFCheckboxes"
                  :key="checkbox.id"
                >
                  <toggle-button
                    color="#1c3a53"
                    v-model="checkbox.value"
                    @change="fillSpecimensAnatomicalRegionFFArray"
                    :sync="true"
                  />

                  <select
                    v-model="checkbox.amount"
                    class="asc-form-input acs-reg-amount"
                    :disabled="!checkbox.value"
                    @change="fillSpecimensAnatomicalRegionFFArray"
                  >
                    <option value="1">1 x</option>
                    <option value="2">2 x</option>
                    <option value="3">3 x</option>
                    <option value="4">4 x</option>
                    <option value="5">5 x</option>
                    <option value="6">6 x</option>
                    <option value="7">7 x</option>
                    <option value="8">8 x</option>
                    <option value="9">9 x</option>
                    <option value="10">10 x</option>
                    <option value="11">11 x</option>
                    <option value="12">12 x</option>
                  </select>
                  {{ checkbox.text }}
                </div>
              </div>
              <div v-if="specimens.specimenTypeId.toString() == '1'">
                <label>
                  Number of specimens required
                  <span class="danger">*</span>
                </label>
                <input
                  v-validate="'required|integer'"
                  name="number of specimens"
                  type="number"
                  class="asc-form-input"
                  :class="{ 'is-danger': errors.has('number of specimens') }"
                  v-model="specimens.numberOfSpecimens"
                />
                <span class="danger">{{
                  errors.first("number of specimens")
                }}</span>
              </div>
              <div v-if="showFullForm">
                <label>
                  The approach (official name) and anatomical areas that will be
                  disturbed during the procedure
                  <span class="danger">*</span>
                </label>
                <textarea
                  v-validate="'required'"
                  name="specimen approach"
                  class="asc-form-input"
                  :class="{ 'is-danger': errors.has('specimen approach') }"
                  rows="6"
                  v-model="specimens.approach"
                ></textarea>
                <span class="danger">{{
                  errors.first("specimen approach")
                }}</span>

                <label>
                  The anatomical regions/structures that need to be intact or
                  present for the successful demonstration of the procedure
                  <span class="danger">*</span>
                </label>
                <input
                  v-validate="'required'"
                  name="anatomical regions/structures"
                  type="text"
                  class="asc-form-input"
                  :class="{
                    'is-danger': errors.has('anatomical regions/structures'),
                  }"
                  v-model="specimens.intactRegions"
                />
                <span class="danger">{{
                  errors.first("anatomical regions/structures")
                }}</span>

                <label>
                  Preferred position of specimen on operating table
                  <span class="danger">*</span>
                </label>
                <select
                  v-validate="'required'"
                  name="position of specimen"
                  class="asc-form-input"
                  :class="[{ 'is-danger': errors.has('position of specimen') }]"
                  v-model="specimens.specimenPositionId"
                >
                  <option
                    v-for="option in dropdownOptions.specimenPositionIdOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <span class="danger">{{
                  errors.first("position of specimen")
                }}</span>

                <div
                  v-if="
                    specimens.specimenPositionId == 5 ||
                    specimens.specimenPositionId == '5'
                  "
                >
                  <label>
                    Other position description
                    <span class="danger">*</span>
                  </label>
                  <input
                    v-validate="'required'"
                    name="other position description"
                    class="asc-form-input"
                    :class="[
                      { 'is-danger': errors.has('other position description') },
                    ]"
                    type="text"
                    v-model="specimens.positionOther"
                  />
                  <span class="danger">{{
                    errors.first("other position description")
                  }}</span>

                  <label>
                    In case of other, upload a diagram to demonstrate
                    accordingly
                    <span class="danger">*</span>
                  </label>
                  <vue-dropzone
                    ref="otherPositionDiagramDropzone"
                    id="otherPositionDiagramDropzone"
                    :options="otherPositionDiagramDropzoneOptions"
                    :use-custom-slot="true"
                    @vdropzone-sending="sendFileDropzone"
                    @vdropzone-removed-file="removeFileOtherPosition"
                    >Drop your file or click here</vue-dropzone
                  >
                </div>

                <label
                  >If the position of the specimen changes throughout the
                  course, please indicate below how and when these changes take
                  place</label
                >
                <textarea
                  class="asc-form-input"
                  rows="6"
                  v-model="specimens.positionChanges"
                ></textarea>
              </div>

              <label>
                Additional option: CT scanned prior
                <span class="danger">*</span>
              </label>
              <select
                v-validate="'required'"
                name="CT scanned prior"
                class="asc-form-input"
                :class="[{ 'is-danger': errors.has('CT scanned prior') }]"
                v-model="specimens.CTScanned"
              >
                <option
                  v-for="option in dropdownOptions.CTScannedOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
              <span class="danger">{{ errors.first("CT scanned prior") }}</span>

              <label>
                Additional option: Pre-fractured
                <span class="danger">*</span>
              </label>
              <select
                v-validate="'required'"
                name="pre-fractured"
                class="asc-form-input"
                :class="[{ 'is-danger': errors.has('pre-fractured') }]"
                v-model="specimens.preFractured"
              >
                <option
                  v-for="option in dropdownOptions.preFracturedOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
              <span class="danger">{{ errors.first("pre-fractured") }}</span>

              <label>
                Ethics committee
                <br />
                <small
                  >Each course is reviewed by an ethics committee. For your
                  course to be evaluated, please answer the following
                  questions.</small
                >
              </label>

              <label>
                Which procedures, described using actual medical terminology,
                will be performed/practiced?
                <span class="danger">*</span>
              </label>
              <textarea
                v-validate="'required'"
                name="specimen procedures"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('specimen procedures') }"
                rows="6"
                v-model="specimens.procedures"
              ></textarea>
              <span class="danger">{{
                errors.first("specimen procedures")
              }}</span>

              <label>
                What is the medical importance/significance of
                learning/practicing this procedure?
                <span class="danger">*</span>
              </label>
              <textarea
                v-validate="'required'"
                name="specimen importance"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('specimen importance') }"
                rows="6"
                v-model="specimens.importance"
              ></textarea>
              <span class="danger">{{
                errors.first("specimen importance")
              }}</span>

              <label>
                What are the benefits of practicing this procedure on donated
                bodies? Could this procedure also be learnt using other mediums
                i.e. simulators etc?
                <span class="danger">*</span>
              </label>
              <textarea
                v-validate="'required'"
                name="specimen benefits"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('specimen benefits') }"
                rows="6"
                v-model="specimens.benefits"
              ></textarea>
              <span class="danger">{{
                errors.first("specimen benefits")
              }}</span>

              <label>
                What are the learning objectives?
                <span class="danger">*</span>
              </label>
              <textarea
                v-validate="'required'"
                name="learning objectives"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('learning objectives') }"
                rows="6"
                v-model="specimens.objectives"
              ></textarea>
              <span class="danger">{{
                errors.first("learning objectives")
              }}</span>

              <label>
                Is this a new or existing course, both in or outside the ASC?
                <span class="danger">*</span>
              </label>
              <input
                v-validate="'required'"
                name="new or existing course"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('new or existing course') }"
                type="text"
                v-model="specimens.existing"
              />
              <span class="danger">{{
                errors.first("new or existing course")
              }}</span>

              <label>
                Is this course endorsed or supported by any professional
                committee or organisation?
                <span class="danger">*</span>
              </label>
              <input
                v-validate="'required'"
                name="course endorsed or supported"
                class="asc-form-input"
                :class="{
                  'is-danger': errors.has('course endorsed or supported'),
                }"
                type="text"
                v-model="specimens.supported"
              />
              <span class="danger">{{
                errors.first("course endorsed or supported")
              }}</span>
            </div>
            <div v-else>
              <label>Are specimens required?</label>
              <select
                class="asc-form-input"
                v-model="wetlabSetups[0].specimens"
              >
                <option
                  v-for="option in dropdownOptions.specimensOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-else>
            <label>
              Wet lab required for event?
              <span class="danger">*</span>
            </label>
            <select
              type="text"
              class="asc-form-input"
              v-model="events.wetlabRequired"
              @change="changeWetlabReqOnTab('specimens')"
            >
              <option
                v-for="option in dropdownOptions.eventWetlabRequiredOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </tab-content>

        <tab-content title="Spaces" :before-change="validateSpaces">
          <label>
            Conference room
            <br />
            <small>
              Conferenceroom opening time: 08:30-16:30. Use of the facility
              beyond these hours is only possible in consultation with the ASC
              and will be charged extra.
              <br />
              <br />The facility has three conference rooms available: Beijing
              (115m2), Mumbai (76m2) and Nairobi(66m2). There is a possibility
              to combine the various rooms into a larger space. The capacity of
              the rooms varies depending on the set-up.
            </small>
          </label>

          <label>Preferred conference rooms</label>
          <div
            class="asc-switch"
            v-for="checkbox in dropdownOptions.conferenceRoomsCheckboxes"
            :key="checkbox.id"
          >
            <toggle-button
              color="#1c3a53"
              v-model="checkbox.value"
              @change="fillconferenceRoomsArray"
              :sync="true"
            />
            {{ checkbox.text }}
          </div>
          <span class="input-sub"
            >The ASC will do their best to provide you with the conferencerooms
            chosen above, howeverthe ASC holds the right to modify the selected
            conferenceroom/s based on group size and availability.</span
          >

          <div
            v-show="
              conferenceroom.locations.includes('1') ||
              conferenceroom.locations.includes(1)
            "
          >
            <label>
              Beijing setup
              <span class="danger">*</span>
              <button
                class="asc-btn asc-btn-sm"
                @click="$modal.show('roomSetup')"
              >
                ?
              </button>
            </label>
            <select
              v-validate="'required'"
              name="Beijing setup"
              class="asc-form-input"
              :class="[{ 'is-danger': errors.has('Beijing setup') }]"
              v-model="conferenceroom.setupBeijing.setupId"
            >
              <option
                v-for="option in dropdownOptions.conferenceRoomSetupOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <span class="danger">{{ errors.first("Beijing setup") }}</span>

            <div v-if="conferenceroom.setupBeijing.setupId == 8">
              <label>
                Specify the 'other' setup for Beijing
                <span class="danger">*</span>
              </label>
              <textarea
                v-validate="'required'"
                name="Beijing other setup"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('Beijing other setup') }"
                rows="6"
                v-model="conferenceroom.setupBeijing.other"
              ></textarea>
              <span class="danger">{{
                errors.first("Beijing other setup")
              }}</span>
            </div>
          </div>

          <div
            v-show="
              conferenceroom.locations.includes('2') ||
              conferenceroom.locations.includes(2)
            "
          >
            <label>
              Mumbai setup
              <span class="danger">*</span>
              <button
                class="asc-btn asc-btn-sm"
                @click="$modal.show('roomSetup')"
              >
                ?
              </button>
            </label>
            <select
              v-validate="'required'"
              name="Mumbai setup"
              class="asc-form-input"
              :class="[{ 'is-danger': errors.has('Mumbai setup') }]"
              v-model="conferenceroom.setupMumbai.setupId"
            >
              <option
                v-for="option in dropdownOptions.conferenceRoomSetupOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <span class="danger">{{ errors.first("Mumbai setup") }}</span>

            <div v-if="conferenceroom.setupMumbai.setupId == 8">
              <label>
                Specify the 'other' setup for Mumbai
                <span class="danger">*</span>
              </label>
              <textarea
                v-validate="'required'"
                name="Mumbai other setup"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('Mumbai other setup') }"
                rows="6"
                v-model="conferenceroom.setupMumbai.other"
              ></textarea>
              <span class="danger">{{
                errors.first("Mumbai other setup")
              }}</span>
            </div>
          </div>

          <div
            v-show="
              conferenceroom.locations.includes('3') ||
              conferenceroom.locations.includes(3)
            "
          >
            <label>
              Nairobi setup
              <span class="danger">*</span>
              <button
                class="asc-btn asc-btn-sm"
                @click="$modal.show('roomSetup')"
              >
                ?
              </button>
            </label>
            <select
              v-validate="'required'"
              name="Nairobi setup"
              class="asc-form-input"
              :class="[{ 'is-danger': errors.has('Nairobi setup') }]"
              v-model="conferenceroom.setupNairobi.setupId"
            >
              <option
                v-for="option in dropdownOptions.conferenceRoomSetupOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <span class="danger">{{ errors.first("Nairobi setup") }}</span>

            <div v-if="conferenceroom.setupNairobi.setupId == 8">
              <label>
                Specify the 'other' setup for Nairobi
                <span class="danger">*</span>
              </label>
              <textarea
                v-validate="'required'"
                name="Nairobi other setup"
                class="asc-form-input"
                :class="{ 'is-danger': errors.has('Nairobi other setup') }"
                rows="6"
                v-model="conferenceroom.setupNairobi.other"
              ></textarea>
              <span class="danger">{{
                errors.first("Nairobi other setup")
              }}</span>
            </div>
          </div>

          <modal name="roomSetup" height="350" width="500">
            <img src="@/assets/img/conferenceroomsetup.png" />
          </modal>

          <div v-if="conferenceroom.locations.length > 1">
            <label>
              Combine conference rooms?
              <span class="danger">*</span>
            </label>
            <select
              v-validate="'required'"
              name="combine rooms"
              class="asc-form-input"
              :class="[{ 'is-danger': errors.has('combine rooms') }]"
              v-model="conferenceroom.combineRooms"
            >
              <option
                v-for="option in dropdownOptions.combineRoomsOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <span class="input-sub"
              >Select yes if you want to combine multiple conference rooms</span
            >
            <span class="danger">{{ errors.first("combine rooms") }}</span>
          </div>
          <div v-if="showFullForm">
            <label>
              Amount of flipcharts/white boards needed?
              <span class="danger">*</span>
            </label>
            <select
              v-validate="'required'"
              name="amount of flipcharts/white boards"
              class="asc-form-input"
              :class="[
                {
                  'is-danger': errors.has('amount of flipcharts/white boards'),
                },
              ]"
              v-model="conferenceroom.numberOfFlipcharts"
            >
              <option
                v-for="option in dropdownOptions.numberOfFlipchartsOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <span class="danger">{{
              errors.first("amount of flipcharts/white boards")
            }}</span>

            <label>
              Custom welcome and info screen required for the welcome desk and
              atrium?
              <span class="danger">*</span>
            </label>
            <select
              v-validate="'required'"
              name="display-screen"
              class="asc-form-input"
              :class="[{ 'is-danger': errors.has('display-screen') }]"
              v-model="conferenceroom.displayScreen"
            >
              <option
                v-for="option in dropdownOptions.displayScreenOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <span class="input-sub"
              >Please provide an image file with the dimentions of 2160 x 3840
              for the atrium screens and 3840 x 600 for the screen at the
              welcome desk</span
            >
            <span class="danger">{{ errors.first("display-screen") }}</span>
          </div>

          <label>Indicate if any additional rooms are required per day</label>
          <br />
          <vue-tabs v-model="additionalRoomsTabIndex">
            <v-tab
              v-for="(
                additionalDay, index
              ) in conferenceroom.additionalRoomsPerDay"
              :key="index"
              :title="'Day ' + (index + 1)"
              :icon="spacesDangerTab(index)"
            >
              <label v-if="agendas.length"
                >Additional rooms required for
                {{
                  agendas[index].eventDayDate | moment("dddd DD MMMM YYYY")
                }}</label
              >
              <div
                class="asc-switch"
                v-for="checkbox in dropdownOptions.additionalRoomsCheckboxes"
                :key="checkbox.id"
              >
                <toggle-button
                  color="#1c3a53"
                  :value="addRoomValue(index, checkbox.id)"
                  :sync="true"
                  @change="changeAddRoomValue(index, checkbox.id)"
                />
                {{ checkbox.title }}
              </div>
              <div
                v-if="
                  conferenceroom.additionalRoomsPerDay[index]
                    .additionalRoomsSelection.length
                "
              >
                <label>
                  Specify the time frames in which the additional rooms are
                  needed.
                  <span class="danger">*</span>
                </label>
                <textarea
                  v-validate="'required'"
                  :name="'additional rooms time frames day ' + (index + 1)"
                  class="asc-form-input"
                  :class="{
                    'is-danger': errors.has(
                      'additional rooms time frames day ' + (index + 1)
                    ),
                  }"
                  rows="6"
                  v-model="
                    conferenceroom.additionalRoomsPerDay[index]
                      .timeFramesDescription
                  "
                ></textarea>
                <span class="danger">{{
                  errors.first(
                    "additional rooms time frames day " + (index + 1)
                  )
                }}</span>
              </div>
            </v-tab>
          </vue-tabs>
        </tab-content>

        <tab-content title="Catering" :before-change="validateCatering">
          <vue-tabs v-model="cateringTabIndex">
            <v-tab
              v-for="(cateringDay, index) in catering"
              :key="index"
              :title="'Day ' + (index + 1)"
            >
              <label v-if="agendas.length"
                >Catering for
                {{
                  agendas[index].eventDayDate | moment("dddd DD MMMM YYYY")
                }}</label
              >
              <button
                class="asc-btn"
                v-if="index > 0"
                @click="copyCatering(index)"
                style="margin-bottom: 20px"
              >
                Copy catering from day {{ index }}
              </button>

              <div class="asc-switch asc-cathering-switch">
                <toggle-button
                  color="#1c3a53"
                  v-model="cateringDay.cateringHasWelcomeReception"
                  :sync="true"
                />
                <span class="asc-catering-label"
                  >Welcome reception
                  <span
                    class="info"
                    v-tooltip="
                      `<div style='text-align:center;'>A great way to welcome your guests at the Amsterdam Skills Centre. <br>Upon arrival we provide coffee, tea, flavoured water accompanied <br>by a selection of fresh pastries and fruit.</div>`
                    "
                    >i</span
                  ></span
                >
                <span v-if="showFullForm">
                  From
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'welcome reception from time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'welcome reception from time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringWelcomeReceptionFromTime"
                    :disabled="!cateringDay.cateringHasWelcomeReception"
                  ></vue-timepicker
                  >till
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'welcome reception till time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'welcome reception till time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringWelcomeReceptionTillTime"
                    :disabled="!cateringDay.cateringHasWelcomeReception"
                  ></vue-timepicker>
                  <div
                    class="danger"
                    v-if="cateringDay.cateringHasWelcomeReception"
                  >
                    <small>{{
                      errors.first(
                        "welcome reception from time day " + (index + 1)
                      )
                    }}</small>
                  </div>
                  <div
                    class="danger"
                    v-if="cateringDay.cateringHasWelcomeReception"
                  >
                    <small>{{
                      errors.first(
                        "welcome reception till time day " + (index + 1)
                      )
                    }}</small>
                  </div>
                </span>
              </div>

              <div class="asc-switch asc-cathering-switch">
                <toggle-button
                  color="#1c3a53"
                  v-model="cateringDay.cateringHasBreak1"
                  :sync="true"
                />
                <span class="asc-catering-label">Morning break</span>
                <span v-if="showFullForm">
                  From
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'break 1 from time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'break 1 from time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringBreak1FromTime"
                    :disabled="!cateringDay.cateringHasBreak1"
                  ></vue-timepicker
                  >till
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'break 1 till time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'break 1 till time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringBreak1TillTime"
                    :disabled="!cateringDay.cateringHasBreak1"
                  ></vue-timepicker>
                  <div class="danger" v-if="cateringDay.cateringHasBreak1">
                    <small>{{
                      errors.first("break 1 from time day " + (index + 1))
                    }}</small>
                  </div>
                  <div class="danger" v-if="cateringDay.cateringHasBreak1">
                    <small>{{
                      errors.first("break 1 till time day " + (index + 1))
                    }}</small>
                  </div>
                </span>
              </div>
              <div
                class="asc-catering"
                v-if="cateringDay.cateringHasBreak1 && showFullForm"
              >
                Morning break menu selection
                <ul v-if="cateringDay.break1.length">
                  <li v-for="(item, i) in cateringDay.break1" :key="i">
                    {{
                      dropdownOptions.cateringBreak1Checkboxes.find(
                        (x) => x.id.toString() === item.toString()
                      ).text
                    }}
                    <div class="asc-catering-list-sub">
                      {{
                        dropdownOptions.cateringBreak1Checkboxes.find(
                          (x) => x.id.toString() === item.toString()
                        ).subText
                      }}
                    </div>
                  </li>
                </ul>
                <ul v-else>
                  <li>There is nothing selected for morning break</li>
                </ul>
                <button
                  class="asc-btn"
                  @click="$modal.show('cateringBreak1_day' + (index + 1))"
                >
                  Edit morning break
                </button>
                <modal
                  :name="'cateringBreak1_day' + (index + 1)"
                  height="auto"
                  :scrollable="true"
                >
                  <div
                    class="asc-switch asc-catering-switch"
                    v-for="checkbox in dropdownOptions.cateringBreak1Checkboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      :value="addCateringBreak1Value(index, checkbox.id)"
                      :sync="true"
                      @change="changeCateringBreak1Value(index, checkbox.id)"
                    />
                    {{ checkbox.text }}
                    <div class="asc-catering-sub">{{ checkbox.subText }}</div>
                  </div>
                </modal>
              </div>

              <div class="asc-switch asc-cathering-switch">
                <toggle-button
                  color="#1c3a53"
                  v-model="cateringDay.cateringHasLunch"
                  :sync="true"
                />
                <span class="asc-catering-label">Lunch</span>
                <span v-if="showFullForm">
                  From
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'lunch from time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'lunch from time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringLunchFromTime"
                    :disabled="!cateringDay.cateringHasLunch"
                  ></vue-timepicker
                  >till
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'lunch till time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'lunch till time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringLunchTillTime"
                    :disabled="!cateringDay.cateringHasLunch"
                  ></vue-timepicker>
                  <div class="danger" v-if="cateringDay.cateringHasLunch">
                    <small>{{
                      errors.first("lunch from time day " + (index + 1))
                    }}</small>
                  </div>
                  <div class="danger" v-if="cateringDay.cateringHasLunch">
                    <small>{{
                      errors.first("lunch till time day " + (index + 1))
                    }}</small>
                  </div>
                </span>
              </div>
              <div
                class="asc-catering"
                v-if="cateringDay.cateringHasLunch && showFullForm"
              >
                Lunch menu selection
                <ul v-if="cateringDay.lunch">
                  <li v-for="(item, i) in cateringDay.lunch" :key="i">
                    {{
                      dropdownOptions.cateringLunchCheckboxes.find(
                        (x) => x.id.toString() === item.toString()
                      )
                        ? dropdownOptions.cateringLunchCheckboxes.find(
                            (x) => x.id.toString() === item.toString()
                          ).text
                        : ""
                    }}
                    <div class="asc-catering-list-sub">
                      {{
                        dropdownOptions.cateringLunchCheckboxes.find(
                          (x) => x.id.toString() === item.toString()
                        )
                          ? dropdownOptions.cateringLunchCheckboxes.find(
                              (x) => x.id.toString() === item.toString()
                            ).subText
                          : ""
                      }}
                    </div>
                  </li>
                </ul>
                <ul v-else>
                  <li>There is nothing selected for lunch</li>
                </ul>
                <button
                  class="asc-btn"
                  @click="$modal.show('cateringLunch_day' + (index + 1))"
                >
                  Edit lunch
                </button>
                <modal
                  :name="'cateringLunch_day' + (index + 1)"
                  height="auto"
                  :scrollable="true"
                >
                  <div
                    class="asc-switch asc-catering-switch"
                    v-for="checkbox in dropdownOptions.cateringLunchCheckboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      :value="addCateringLunchValue(index, checkbox.id)"
                      :sync="true"
                      @change="changeCateringLunchValue(index, checkbox.id)"
                    />
                    {{ checkbox.text }}
                    <div class="asc-catering-sub">{{ checkbox.subText }}</div>
                  </div>
                </modal>
                <br />
                <br />
                <br />Lunch add-ons
                <ul v-if="cateringDay.lunchAddOn.length">
                  <li v-for="(item, i) in cateringDay.lunchAddOn" :key="i">
                    {{
                      dropdownOptions.cateringLunchAddOnCheckboxes.find(
                        (x) => x.id.toString() === item.toString()
                      ).text
                    }}
                    <div class="asc-catering-list-sub">
                      {{
                        dropdownOptions.cateringLunchAddOnCheckboxes.find(
                          (x) => x.id.toString() === item.toString()
                        ).subText
                      }}
                    </div>
                  </li>
                </ul>
                <ul v-else>
                  <li>There is nothing selected for lunch add-ons</li>
                </ul>
                <button
                  class="asc-btn"
                  @click="$modal.show('cateringLunchAddOn_day' + (index + 1))"
                >
                  Edit lunch add-ons
                </button>
                <modal
                  :name="'cateringLunchAddOn_day' + (index + 1)"
                  height="auto"
                  :scrollable="true"
                >
                  <div
                    class="asc-switch asc-catering-switch"
                    v-for="checkbox in dropdownOptions.cateringLunchAddOnCheckboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      :value="addCateringLunchAddOnValue(index, checkbox.id)"
                      :sync="true"
                      @change="
                        changeCateringLunchAddOnValue(index, checkbox.id)
                      "
                    />
                    {{ checkbox.text }}
                    <div class="asc-catering-sub">{{ checkbox.subText }}</div>
                  </div>
                </modal>
                <br />
                <br />
                <br />Dessert
                <ul v-if="cateringDay.dessert.length">
                  <li v-for="(item, i) in cateringDay.dessert" :key="i">
                    {{
                      dropdownOptions.cateringDessertCheckboxes.find(
                        (x) => x.id.toString() === item.toString()
                      ).text
                    }}

                    {{
                      dropdownOptions.cateringDessertCheckboxes.find(
                        (x) => x.id.toString() === item.toString()
                      ).text
                    }}
                    <div class="asc-catering-list-sub">
                      {{
                        dropdownOptions.cateringDessertCheckboxes.find(
                          (x) => x.id.toString() === item.toString()
                        ).subText
                      }}
                    </div>
                  </li>
                </ul>
                <ul v-else>
                  <li>There is nothing selected for dessert</li>
                </ul>
                <button
                  class="asc-btn"
                  @click="$modal.show('cateringDessert_day' + (index + 1))"
                >
                  Edit dessert
                </button>
                <modal
                  :name="'cateringDessert_day' + (index + 1)"
                  height="auto"
                  :scrollable="true"
                >
                  <div
                    class="asc-switch asc-catering-switch"
                    v-for="checkbox in dropdownOptions.cateringDessertCheckboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      :value="addCateringDessertValue(index, checkbox.id)"
                      :sync="true"
                      @change="changeCateringDessertValue(index, checkbox.id)"
                    />
                    {{ checkbox.text }}
                    <div class="asc-catering-sub">{{ checkbox.subText }}</div>
                  </div>
                </modal>
              </div>

              <div class="asc-switch asc-cathering-switch">
                <toggle-button
                  color="#1c3a53"
                  v-model="cateringDay.cateringHasBreak2"
                  :sync="true"
                />
                <span class="asc-catering-label">Afternoon break</span>
                <span v-if="showFullForm">
                  From
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'break 2 from time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'break 2 from time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringBreak2FromTime"
                    :disabled="!cateringDay.cateringHasBreak2"
                  ></vue-timepicker
                  >till
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'break 2 till time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'break 2 till time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringBreak2TillTime"
                    :disabled="!cateringDay.cateringHasBreak2"
                  ></vue-timepicker>
                  <div class="danger" v-if="cateringDay.cateringHasBreak2">
                    <small>{{
                      errors.first("break 2 from time day " + (index + 1))
                    }}</small>
                  </div>
                  <div class="danger" v-if="cateringDay.cateringHasBreak2">
                    <small>{{
                      errors.first("break 2 till time day " + (index + 1))
                    }}</small>
                  </div>
                </span>
              </div>
              <div
                class="asc-catering"
                v-if="cateringDay.cateringHasBreak2 && showFullForm"
              >
                Afternoon break menu selection
                <ul v-if="cateringDay.break2.length">
                  <li v-for="(item, i) in cateringDay.break2" :key="i">
                    {{
                      dropdownOptions.cateringBreak2Checkboxes.find(
                        (x) => x.id.toString() === item.toString()
                      ).text
                    }}
                    <div class="asc-catering-list-sub">
                      {{
                        dropdownOptions.cateringBreak2Checkboxes.find(
                          (x) => x.id.toString() === item.toString()
                        ).subText
                      }}
                    </div>
                  </li>
                </ul>
                <ul v-else>
                  <li>There is nothing selected for afternoon break</li>
                </ul>
                <button
                  class="asc-btn"
                  @click="$modal.show('cateringBreak2_day' + (index + 1))"
                >
                  Edit afternoon break
                </button>
                <modal
                  :name="'cateringBreak2_day' + (index + 1)"
                  height="auto"
                  :scrollable="true"
                >
                  <div
                    class="asc-switch asc-catering-switch"
                    v-for="checkbox in dropdownOptions.cateringBreak2Checkboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      :value="addCateringBreak2Value(index, checkbox.id)"
                      :sync="true"
                      @change="changeCateringBreak2Value(index, checkbox.id)"
                    />
                    {{ checkbox.text }}
                    <div class="asc-catering-sub">{{ checkbox.subText }}</div>
                  </div>
                </modal>
              </div>

              <div class="asc-switch asc-cathering-switch">
                <toggle-button
                  color="#1c3a53"
                  v-model="cateringDay.cateringHasBreak3"
                  :sync="true"
                />
                <span class="asc-catering-label">Closing reception</span>
                <span v-if="showFullForm">
                  From
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'break 3 from time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'break 3 from time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringBreak3FromTime"
                    :disabled="!cateringDay.cateringHasBreak3"
                  ></vue-timepicker
                  >till
                  <vue-timepicker
                    v-validate="'required'"
                    input-class="skip-error-style"
                    :name="'break 3 till time day ' + (index + 1)"
                    class="asc-catering-time"
                    :class="[
                      {
                        'is-danger': errors.has(
                          'break 3 till time day ' + (index + 1)
                        ),
                      },
                    ]"
                    :minute-interval="15"
                    v-model="cateringDay.cateringBreak3TillTime"
                    :disabled="!cateringDay.cateringHasBreak3"
                  ></vue-timepicker>
                  <div class="danger" v-if="cateringDay.cateringHasBreak3">
                    <small>{{
                      errors.first("break 3 from time day " + (index + 1))
                    }}</small>
                  </div>
                  <div class="danger" v-if="cateringDay.cateringHasBreak3">
                    <small>{{
                      errors.first("break 3 till time day " + (index + 1))
                    }}</small>
                  </div>
                </span>
              </div>
              <div
                class="asc-catering"
                v-if="cateringDay.cateringHasBreak3 && showFullForm"
              >
                Closing reception menu selection
                <ul v-if="cateringDay.break3.length">
                  <li v-for="(item, i) in cateringDay.break3" :key="i">
                    {{
                      dropdownOptions.cateringBreak3Checkboxes.find(
                        (x) => x.id.toString() === item.toString()
                      ).text
                    }}
                    <div class="asc-catering-list-sub">
                      {{
                        dropdownOptions.cateringBreak3Checkboxes.find(
                          (x) => x.id.toString() === item.toString()
                        ).subText
                      }}
                    </div>
                  </li>
                </ul>
                <ul v-else>
                  <li>There is nothing selected for closing reception</li>
                </ul>
                <button
                  class="asc-btn"
                  @click="$modal.show('cateringBreak3_day' + (index + 1))"
                >
                  Edit closing reception
                </button>
                <modal
                  :name="'cateringBreak3_day' + (index + 1)"
                  height="auto"
                  :scrollable="true"
                >
                  <div
                    class="asc-switch asc-catering-switch"
                    v-for="checkbox in dropdownOptions.cateringBreak3Checkboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      :value="addCateringBreak3Value(index, checkbox.id)"
                      :sync="true"
                      @change="changeCateringBreak3Value(index, checkbox.id)"
                    />
                    {{ checkbox.text }}
                    <div class="asc-catering-sub">{{ checkbox.subText }}</div>
                  </div>
                </modal>
              </div>

              <div
                class="asc-catering"
                :style="!cateringDay.cateringHasLunch ? 'margin-top:40px;' : ''"
                v-if="showFullForm"
              >
                Drinks
                <ul v-if="cateringDay.drink">
                  <li v-for="(item, i) in cateringDay.drink" :key="i">
                    {{
                      dropdownOptions.cateringDrinkCheckboxes.find(
                        (x) => x.id.toString() === item.toString()
                      ).text
                    }}
                    <div class="asc-catering-list-sub">
                      {{
                        dropdownOptions.cateringDrinkCheckboxes.find(
                          (x) => x.id.toString() === item.toString()
                        ).subText
                      }}
                    </div>
                  </li>
                </ul>
                <ul v-else>
                  <li>There is nothing selected for drinks</li>
                </ul>
                <button
                  class="asc-btn"
                  @click="$modal.show('cateringDrinks_day' + (index + 1))"
                >
                  Edit drinks
                </button>
                <modal
                  :name="'cateringDrinks_day' + (index + 1)"
                  height="auto"
                  :scrollable="true"
                >
                  <div
                    class="asc-switch asc-catering-switch"
                    v-for="checkbox in dropdownOptions.cateringDrinkCheckboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      :value="addCateringDrinksValue(index, checkbox.id)"
                      :sync="true"
                      @change="changeCateringDrinksValue(index, checkbox.id)"
                    />
                    {{ checkbox.text }}
                    <div class="asc-catering-sub">{{ checkbox.subText }}</div>
                  </div>
                </modal>
              </div>

              <div class="asc-catering" v-if="showFullForm">
                Snacks
                <ul v-if="cateringDay.snack">
                  <li v-for="(item, i) in cateringDay.snack" :key="i">
                    {{
                      dropdownOptions.cateringSnackCheckboxes.find(
                        (x) => x.id.toString() === item.toString()
                      ).text
                    }}
                    <div class="asc-catering-list-sub">
                      {{
                        dropdownOptions.cateringSnackCheckboxes.find(
                          (x) => x.id.toString() === item.toString()
                        ).subText
                      }}
                    </div>
                  </li>
                </ul>
                <ul v-else>
                  <li>There is nothing selected for snacks</li>
                </ul>
                <button
                  class="asc-btn"
                  @click="$modal.show('cateringSnack_day' + (index + 1))"
                >
                  Edit snacks
                </button>
                <modal
                  :name="'cateringSnack_day' + (index + 1)"
                  height="auto"
                  :scrollable="true"
                >
                  <div
                    class="asc-switch asc-catering-switch"
                    v-for="checkbox in dropdownOptions.cateringSnackCheckboxes"
                    :key="checkbox.id"
                  >
                    <toggle-button
                      color="#1c3a53"
                      :value="addCateringSnackValue(index, checkbox.id)"
                      :sync="true"
                      @change="changeCateringSnackValue(index, checkbox.id)"
                    />
                    {{ checkbox.text }}
                    <div class="asc-catering-sub">{{ checkbox.subText }}</div>
                  </div>
                </modal>
              </div>
              <label>Special dietary requests</label>
              <textarea
                rows="5"
                class="asc-form-input"
                v-model="cateringDay.dietaryRequest"
              ></textarea>
            </v-tab>
          </vue-tabs>
        </tab-content>
      </form-wizard>
    </div>
    <modal name="saveModal" height="235" width="400" classes="accept-modal">
      <h2>Thank you</h2>
      <p>Your data has been saved</p>
      <br />
      <button class="asc-btn" @click="$modal.hide('saveModal')">Ok</button>
    </modal>
    <modal name="dennisModal" height="335" width="320" classes="accept-modal">
      <h2>There was a problem saving your data</h2>
      <p>{{ saveErrorMsg }}</p>
      <p>Were sorry... please report the problem</p>
      <br />
      <button class="asc-btn" @click="$modal.hide('dennisModal')">Ok</button>
    </modal>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import { FormWizard, TabContent } from "vue-form-wizard"; //https://binarcode.github.io/vue-form-wizard/
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import moment from "moment"; //https://www.npmjs.com/package/vue-moment
import vue2Dropzone from "vue2-dropzone"; //https://rowanwins.github.io/vue-dropzone/docs/dist/#/installation
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { VueTabs, VTab } from "vue-nav-tabs"; //https://cristijora.github.io/vue-tabs/#/
import "vue-nav-tabs/themes/vue-tabs.css";
import Datepicker from "vuejs-datepicker"; //https://www.npmjs.com/package/vuejs-datepicker
import VueTimepicker from "vue2-timepicker"; //https://phoenixwong.github.io/vue2-timepicker/
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    Header,
    FormWizard,
    TabContent,
    vueDropzone: vue2Dropzone,
    VueTabs,
    VTab,
    VueTimepicker,
    Datepicker,
  },
  data() {
    return {
      loading: true,
      submitBtn: false,
      saveErrorMsg: "",
      attendeeUploadError: false,
      agendaUploadError: false,
      showFullForm: false,
      timeSchedulesTabIndex: 0,
      wetLabTabIndex: 0,
      wetLabSetupTabIndex: 0,
      additionalRoomsTabIndex: 0,
      cateringTabIndex: 0,
      apiBaseUrl:
        window.location.href.indexOf("localhost") > -1 ||
        window.location.href.indexOf("lwdev") > -1
          ? "https://amsterdamskillcenter-requestform-api.lwdev.nl/"
          : "https://amsterdamskillcenter-requestform-api.lwprod.nl/",
      attendeesDropzoneOptions: {
        url:
          window.location.href.indexOf("localhost") > -1 ||
          window.location.href.indexOf("lwdev") > -1
            ? "https://amsterdamskillcenter-requestform-api.lwdev.nl/uploadAttendeeList"
            : "https://amsterdamskillcenter-requestform-api.lwprod.nl/uploadAttendeeList",
        addRemoveLinks: true,
      },
      agendaDropzoneOptions: {
        url:
          window.location.href.indexOf("localhost") > -1 ||
          window.location.href.indexOf("lwdev") > -1
            ? "https://amsterdamskillcenter-requestform-api.lwdev.nl/uploadDraftAgenda"
            : "https://amsterdamskillcenter-requestform-api.lwprod.nl/uploadDraftAgenda",
        addRemoveLinks: true,
      },
      attendeeListDropzoneOptions: {
        url:
          window.location.href.indexOf("localhost") > -1 ||
          window.location.href.indexOf("lwdev") > -1
            ? "https://amsterdamskillcenter-requestform-api.lwdev.nl/uploadAttendeeList"
            : "https://amsterdamskillcenter-requestform-api.lwprod.nl/uploadAttendeeList",
        addRemoveLinks: true,
      },
      otherPositionDiagramDropzoneOptions: {
        url:
          window.location.href.indexOf("localhost") > -1 ||
          window.location.href.indexOf("lwdev") > -1
            ? "https://amsterdamskillcenter-requestform-api.lwdev.nl/uploadSpecimenPosition"
            : "https://amsterdamskillcenter-requestform-api.lwprod.nl/uploadSpecimenPosition",
        addRemoveLinks: true,
      },
      appUser: {},
      events: {},
      attendees: {},
      agendas: [],
      agendasBackup: [],
      wetlabAgendas: [],
      wetlabAgendasBackup: [],
      wetlabSetups: [],
      specimens: {},
      conferenceroom: {},
      conferenceroomAdditionalRoomsPerDayBackup: [],
      catering: [],
      cateringBackup: [],
      //HIERONDER toegevoegd, alle dropdown opties en checkbox opties
      dropdownOptions: {},
    };
  },
  computed: {
    wetLabSetupTabNeeded() {
      let wetLabNeededAnyDay = this.wetlabAgendas.find(
        (i) => i.wetLabNeededThisDay === true
      );
      if (
        wetLabNeededAnyDay &&
        this.events.wetlabRequired.toLowerCase().trim() !== "no"
      ) {
        return true;
      } else {
        return false;
      }
    },
    externalCarmsNeeded() {
      return this.wetlabSetups[this.wetLabSetupTabIndex].CArmsDays > 0
        ? true
        : false;
    },
  },
  methods: {
    changeFullForm() {
      this.showFullForm = !this.showFullForm;
      this.timeSchedulesTabIndex = 0;
      this.wetLabTabIndex = 0;
      this.wetLabSetupTabIndex = 0;
      this.additionalRoomsTabIndex = 0;
      this.cateringTabIndex = 0;
    },
    saveForm(noModal) {
      let saveUrlParams = "";
      if (this.$route.query.eventId) {
        saveUrlParams = "?eventId=" + this.$route.query.eventId;
      } else if (this.$route.query.moodleUI) {
        saveUrlParams = "?moodleUI=" + this.$route.query.moodleUI;
      }
      let saveToken = "";
      if (this.$route.query.token) {
        saveToken = "&token=" + this.$route.query.token;
      }
      saveUrlParams = saveUrlParams + saveToken;
      this.saveErrorMsg = "";
      this.axios
        .post(this.apiBaseUrl + "submitForm" + saveUrlParams, {
          showFullForm: this.showFullForm,
          agendas: this.agendas,
          appUser: this.appUser,
          attendees: this.attendees,
          catering: this.catering,
          conferenceroom: this.conferenceroom,
          dropdownOptions: this.dropdownOptions,
          events: this.events,
          specimens: this.specimens,
          wetlabAgendas: this.wetlabAgendas,
          wetlabSetup: Array.isArray(this.wetlabSetups)
            ? this.wetlabSetups
            : [this.wetlabSetups],
          submit: this.submitBtn,
        })
        .then((response) => {
          this.submitBtn = false;
          if (response.data.data) {
            if (response.data.data.appUserId) {
              this.appUser.id = response.data.data.appUserId;
            }
            if (!noModal) {
              this.$modal.show("saveModal");
            }
          } else {
            if (response.data.message) {
              this.saveErrorMsg = response.data.message;
            }
            this.$modal.show("dennisModal");
          }
          this.events.id = response.data.data.eventId;
          this.attendees.eventId = response.data.data.eventId;
        });
    },
    submitForm() {
      console.warn("Sumbit form!");
      this.submitBtn = true;
      this.saveForm(false);
    },
    copyAgendaTimes(tab) {
      this.agendas[tab].startTime = this.agendas[tab - 1].startTime;
      this.agendas[tab].endTime = this.agendas[tab - 1].endTime;
      this.agendas[tab].staffArriveTime = this.agendas[tab - 1].staffArriveTime;
      this.agendas[tab].staffLeaveTime = this.agendas[tab - 1].staffLeaveTime;
    },
    copyWetLabAgendaTimes(tab) {
      this.wetlabAgendas[tab].startTimePreference =
        this.wetlabAgendas[tab - 1].startTimePreference;
      this.wetlabAgendas[tab].endTimePreference =
        this.wetlabAgendas[tab - 1].endTimePreference;
      this.wetlabAgendas[tab].setupTime = this.wetlabAgendas[tab - 1].setupTime;
      this.wetlabAgendas[tab].preferredSetupTimeslotId =
        this.wetlabAgendas[tab - 1].preferredSetupTimeslotId;
      this.wetlabAgendas[tab].breakupTime =
        this.wetlabAgendas[tab - 1].breakupTime;
      setTimeout(() => {
        this.$validator.validate(
          "preferred setup time slot/moment day " + (tab + 1)
        );
      }, 10);
    },
    copyCatering(tab) {
      this.catering[tab].cateringHasBreak1 =
        this.catering[tab - 1].cateringHasBreak1;
      this.catering[tab].cateringBreak1FromTime =
        this.catering[tab - 1].cateringBreak1FromTime;
      this.catering[tab].cateringBreak1TillTime =
        this.catering[tab - 1].cateringBreak1TillTime;
      this.catering[tab].break1 = this.catering[tab - 1].break1;

      this.catering[tab].cateringHasBreak2 =
        this.catering[tab - 1].cateringHasBreak2;
      this.catering[tab].cateringBreak2FromTime =
        this.catering[tab - 1].cateringBreak2FromTime;
      this.catering[tab].cateringBreak2TillTime =
        this.catering[tab - 1].cateringBreak2TillTime;
      this.catering[tab].break2 = this.catering[tab - 1].break2;

      this.catering[tab].cateringHasBreak3 =
        this.catering[tab - 1].cateringHasBreak3;
      this.catering[tab].cateringBreak3FromTime =
        this.catering[tab - 1].cateringBreak3FromTime;
      this.catering[tab].cateringBreak3TillTime =
        this.catering[tab - 1].cateringBreak3TillTime;
      this.catering[tab].break3 = this.catering[tab - 1].break3;

      this.catering[tab].cateringHasLunch =
        this.catering[tab - 1].cateringHasLunch;
      this.catering[tab].cateringLunchFromTime =
        this.catering[tab - 1].cateringLunchFromTime;
      this.catering[tab].cateringLunchTillTime =
        this.catering[tab - 1].cateringLunchTillTime;
      this.catering[tab].lunch = this.catering[tab - 1].lunch;

      this.catering[tab].lunchAddOn = this.catering[tab - 1].lunchAddOn;
      this.catering[tab].dessert = this.catering[tab - 1].dessert;
      this.catering[tab].drink = this.catering[tab - 1].drink;
      this.catering[tab].snack = this.catering[tab - 1].snack;
      this.catering[tab].dietaryRequest = this.catering[tab - 1].dietaryRequest;
    },
    agendaDangerTab(index) {
      if (this.showFullForm) {
        return this.$validator.errors.has("start time day " + (index + 1)) ||
          this.$validator.errors.has("end time day " + (index + 1)) ||
          this.$validator.errors.has("staff arrival time day " + (index + 1)) ||
          this.$validator.errors.has("staff departure time day " + (index + 1))
          ? "danger-dot"
          : "";
      } else {
        return this.$validator.errors.has("start time day " + (index + 1)) ||
          this.$validator.errors.has("end time day " + (index + 1))
          ? "danger-dot"
          : "";
      }
    },
    wetlabSetupDangerTab(index) {
      return this.$validator.errors.has(
        "number of operating stations" + index
      ) ||
        this.$validator.errors.has("washing/sterilisation wishes" + index) ||
        this.$validator.errors.has("specimens required" + index) ||
        this.$validator.errors.has("number of kits to be washed" + index) ||
        this.$validator.errors.has("number of kits to be sterilized" + index) ||
        this.$validator.errors.has("external C-arms for days" + index)
        ? "danger-dot"
        : "";
    },

    wetLabAgendaDangerTab(index) {
      return this.$validator.errors.has(
        "start time in wet lab day " + (index + 1)
      ) ||
        this.$validator.errors.has("end time in wet lab day " + (index + 1)) ||
        this.$validator.errors.has("setup time day " + (index + 1)) ||
        this.$validator.errors.has(
          "preferred setup time slot/moment day " + (index + 1)
        ) ||
        this.$validator.errors.has("breakdown time day " + (index + 1))
        ? "danger-dot"
        : "";
    },
    spacesDangerTab(index) {
      return this.$validator.errors.has(
        "additional rooms time frames day " + (index + 1)
      )
        ? "danger-dot"
        : "";
    },
    fillWetlabSetupMiscellaneousArray(index) {
      this.wetlabSetups[index].miscellaneous = [];
      this.dropdownOptions.wetlabMiscellaneousCheckboxes.forEach((i) => {
        if (i.value) {
          this.wetlabSetups[index].miscellaneous.push(i.id);
        }
      });
    },
    fillSpecimensAnatomicalRegionFFArray() {
      this.specimens.anatomicalRegionFF = [];
      this.dropdownOptions.specimensAnatomicalRegionFFCheckboxes.forEach(
        (i) => {
          if (i.value) {
            this.specimens.anatomicalRegionFF.push({
              id: i.id,
              amount: parseInt(i.amount),
            });
          }
        }
      );
    },
    fillconferenceRoomsArray() {
      this.conferenceroom.locations = [];
      this.dropdownOptions.conferenceRoomsCheckboxes.forEach((i) => {
        if (i.value) {
          this.conferenceroom.locations.push(i.id);
        }
      });
    },
    fillCateringBreak1Array(index) {
      this.catering[index].break1 = [];
      this.dropdownOptions.cateringBreak1Checkboxes.forEach((i) => {
        if (i.value) {
          this.catering[index].break1.push(i.id);
        }
      });
    },
    fillCateringBreak2Array(index) {
      this.catering[index].break2 = [];
      this.dropdownOptions.cateringBreak2Checkboxes.forEach((i) => {
        if (i.value) {
          this.catering[index].break2.push(i.id);
        }
      });
    },
    addRoomValue(index, addRoomId) {
      return this.conferenceroom.additionalRoomsPerDay[
        index
      ].additionalRoomsSelection.includes(addRoomId)
        ? true
        : false;
    },
    changeAddRoomValue(index, addRoomId) {
      if (
        this.conferenceroom.additionalRoomsPerDay[
          index
        ].additionalRoomsSelection.includes(addRoomId)
      ) {
        let array =
          this.conferenceroom.additionalRoomsPerDay[index]
            .additionalRoomsSelection;
        let filtered = array.filter(function (value, index, arr) {
          return value !== addRoomId;
        });
        this.conferenceroom.additionalRoomsPerDay[
          index
        ].additionalRoomsSelection = filtered.sort((a, b) => a - b);
      } else {
        let array =
          this.conferenceroom.additionalRoomsPerDay[index]
            .additionalRoomsSelection;
        array.push(addRoomId);
        this.conferenceroom.additionalRoomsPerDay[
          index
        ].additionalRoomsSelection = array.sort((a, b) => a - b);
      }
    },
    changeExternalCarmsNeeded() {
      if (this.wetlabSetups[this.wetLabSetupTabIndex].CArmsDays > 0) {
        this.wetlabSetups[this.wetLabSetupTabIndex].CArmsDays = 0;
      } else {
        this.wetlabSetups[this.wetLabSetupTabIndex].CArmsDays = 1;
      }
    },
    addCateringBreak1Value(index, id) {
      return this.catering[index].break1.includes(id) ? true : false;
    },
    changeCateringBreak1Value(index, id) {
      if (this.catering[index].break1.includes(id)) {
        let array = this.catering[index].break1;
        let filtered = array.filter(function (value, index, arr) {
          return value !== id;
        });
        this.catering[index].break1 = filtered.sort((a, b) => a - b);
      } else {
        let array = this.catering[index].break1;
        array.push(id);
        this.catering[index].break1 = array.sort((a, b) => a - b);
      }
    },
    addCateringBreak2Value(index, id) {
      return this.catering[index].break2.includes(id) ? true : false;
    },
    changeCateringBreak2Value(index, id) {
      if (this.catering[index].break2.includes(id)) {
        let array = this.catering[index].break2;
        let filtered = array.filter(function (value, index, arr) {
          return value !== id;
        });
        this.catering[index].break2 = filtered.sort((a, b) => a - b);
      } else {
        let array = this.catering[index].break2;
        array.push(id);
        this.catering[index].break2 = array.sort((a, b) => a - b);
      }
    },
    addCateringBreak3Value(index, id) {
      return this.catering[index].break3.includes(id) ? true : false;
    },
    changeCateringBreak3Value(index, id) {
      if (this.catering[index].break3.includes(id)) {
        let array = this.catering[index].break3;
        let filtered = array.filter(function (value, index, arr) {
          return value !== id;
        });
        this.catering[index].break3 = filtered.sort((a, b) => a - b);
      } else {
        let array = this.catering[index].break3;
        array.push(id);
        this.catering[index].break3 = array.sort((a, b) => a - b);
      }
    },
    addCateringLunchValue(index, id) {
      return this.catering[index].lunch.includes(id) ? true : false;
    },
    changeCateringLunchValue(index, id) {
      if (this.catering[index].lunch.includes(id)) {
        let array = this.catering[index].lunch;
        let filtered = array.filter(function (value, index, arr) {
          return value !== id;
        });
        this.catering[index].lunch = filtered.sort((a, b) => a - b);
      } else {
        let array = this.catering[index].lunch;
        array.push(id);
        this.catering[index].lunch = array.sort((a, b) => a - b);
      }
    },
    addCateringLunchAddOnValue(index, id) {
      return this.catering[index].lunchAddOn.includes(id) ? true : false;
    },
    changeCateringLunchAddOnValue(index, id) {
      if (this.catering[index].lunchAddOn.includes(id)) {
        let array = this.catering[index].lunchAddOn;
        let filtered = array.filter(function (value, index, arr) {
          return value !== id;
        });
        this.catering[index].lunchAddOn = filtered.sort((a, b) => a - b);
      } else {
        let array = this.catering[index].lunchAddOn;
        array.push(id);
        this.catering[index].lunchAddOn = array.sort((a, b) => a - b);
      }
    },
    addCateringDessertValue(index, id) {
      return this.catering[index].dessert.includes(id) ? true : false;
    },
    changeCateringDessertValue(index, id) {
      if (this.catering[index].dessert.includes(id)) {
        let array = this.catering[index].dessert;
        let filtered = array.filter(function (value, index, arr) {
          return value !== id;
        });
        this.catering[index].dessert = filtered.sort((a, b) => a - b);
      } else {
        let array = this.catering[index].dessert;
        array.push(id);
        this.catering[index].dessert = array.sort((a, b) => a - b);
      }
    },
    addCateringDrinksValue(index, id) {
      if (!this.catering[index].drink) {
        return false;
      }
      return this.catering[index].drink.includes(id) ? true : false;
    },
    changeCateringDrinksValue(index, id) {
      if (this.catering[index].drink.includes(id)) {
        let array = this.catering[index].drink;
        let filtered = array.filter(function (value, index, arr) {
          return value !== id;
        });
        this.catering[index].drink = filtered.sort((a, b) => a - b);
      } else {
        let array = this.catering[index].drink;
        array.push(id);
        this.catering[index].drink = array.sort((a, b) => a - b);
      }
    },
    addCateringSnackValue(index, id) {
      if (!this.catering[index].snack) {
        return false;
      }
      return this.catering[index].snack.includes(id) ? true : false;
    },
    changeCateringSnackValue(index, id) {
      if (this.catering[index].snack.includes(id)) {
        let array = this.catering[index].snack;
        let filtered = array.filter(function (value, index, arr) {
          return value !== id;
        });
        this.catering[index].snack = filtered.sort((a, b) => a - b);
      } else {
        let array = this.catering[index].snack;
        array.push(id);
        this.catering[index].snack = array.sort((a, b) => a - b);
      }
    },
    async validateAppuser() {
      this.$scrollTo("#ascForm");
      const results = Promise.all([
        this.$validator.validate("prefix"),
        this.$validator.validate("first name"),
        this.$validator.validate("last name"),
        this.$validator.validate("company/hospital name"),
        this.$validator.validate("division/department"),
        this.$validator.validate("e-mail"),
        this.$validator.validate("additional e-mail"),
        this.$validator.validate("mobile number"),
        this.$validator.validate("billing details"),
      ]);
      const areValid = (await results).every((isValid) => isValid);
      return areValid;
    },
    async validateEvent() {
      this.$scrollTo("#ascForm");
      if (this.showFullForm) {
        const results = Promise.all([
          this.$validator.validate("event/course title"),
          this.$validator.validate("start date"),
          this.$validator.validate("discipline"),
          this.$validator.validate("event length"),
          this.$validator.validate("wet lab required"),
          this.$validator.validate("event description"),
          this.$validator.validate("CME points"),
          this.$validator.validate("event sponsored"),
        ]);
        const areValid = (await results).every((isValid) => isValid);
        if (
          areValid &&
          (this.events.sponsored === "1" || this.events.sponsored === 1)
        ) {
          const resultsExtra = Promise.all([
            this.$validator.validate("sponsor name"),
          ]);
          const areValidExtra = (await resultsExtra).every(
            (isValid) => isValid
          );
          if (areValid) {
            this.saveForm(true);
          }
          return areValidExtra;
        } else {
          if (areValid) {
            this.saveForm(true);
          }
          return areValid;
        }
      } else {
        const results = Promise.all([
          this.$validator.validate("start date"),
          this.$validator.validate("event length"),
          this.$validator.validate("wet lab required"),
          this.$validator.validate("event description"),
        ]);
        const areValid = (await results).every((isValid) => isValid);
        if (areValid) {
          this.saveForm(true);
        }
        return areValid;
      }
    },
    async validateAttendees() {
      this.$scrollTo("#ascForm");

      this.attendeeUploadError = false;

      const zone = document.getElementById("attendeesDropzone");
      const dzPreviewElements = zone.getElementsByClassName("dz-preview");

      if (this.attendees.numberOfParticipants < 0) {
        this.attendees.numberOfParticipants = 0;
      }
      if (this.attendees.numberOfSupervisors < 0) {
        this.attendees.numberOfSupervisors = 0;
      }
      if (this.attendees.numberOfRepresentatives < 0) {
        this.attendees.numberOfRepresentatives = 0;
      }

      if (this.showFullForm) {
        const results = Promise.all([
          /* OOK CHECK OP FILE UPLOAD ATTENDEES LIST TOEVOEGEN!!!! */
          this.$validator.validate("target audience description"),
          this.$validator.validate("number of participants"),
          this.$validator.validate("number of supervisors"),
          this.$validator.validate("number of representatives"),
        ]);

        let areValid = (await results).every((isValid) => isValid);
        if (dzPreviewElements.length === 0) {
          areValid = false;
          this.attendeeUploadError = true;
        }

        return areValid;
      } else {
        const results = Promise.all([
          this.$validator.validate("number of participants"),
          this.$validator.validate("number of supervisors"),
          this.$validator.validate("number of representatives"),
        ]);

        let areValid = (await results).every((isValid) => isValid);
        if (dzPreviewElements.length === 0) {
          areValid = false;
          this.attendeeUploadError = true;
        }
        return areValid;
      }
    },
    async validateAgenda() {
      this.$scrollTo("#ascForm");
      this.agendaUploadError = false;
      let areValid = true;
      let i = 0;

      const zone = document.getElementById("agendaDropzone");
      const dzPreviewElements = zone.getElementsByClassName("dz-preview");

      if (dzPreviewElements.length === 0) {
        areValid = false;
        this.agendaUploadError = true;
      }

      if (this.showFullForm) {
        for (let agenda of this.agendas) {
          i++;
          let results = Promise.all([
            this.$validator.validate("start time day " + i),
            this.$validator.validate("end time day " + i),
            this.$validator.validate("staff arrival time day " + i),
            this.$validator.validate("staff departure time day " + i),
          ]);
          if (areValid) {
            areValid = (await results).every((isValid) => isValid);
          }
        }
      } else {
        for (let agenda of this.agendas) {
          i++;
          let results = Promise.all([
            this.$validator.validate("start time day " + i),
            this.$validator.validate("end time day " + i),
          ]);
          if (areValid) {
            areValid = (await results).every((isValid) => isValid);
          }
        }
      }
      return areValid;
    },
    validateWetLabAgenda() {
      this.$scrollTo("#ascForm");
      let areValid = true;
      let i = 0;
      for (let agenda of this.wetlabAgendas) {
        i++;
        if (agenda.wetLabNeededThisDay) {
          this.$validator.validate("start time in wet lab day " + i);
          this.$validator.validate("end time in wet lab day " + i);
          this.$validator.validate("setup time day " + i);
          this.$validator.validate("preferred setup time slot/moment day " + i);
          this.$validator.validate("breakdown time day " + i);
          if (
            !agenda.startTimePreference ||
            !agenda.endTimePreference ||
            !agenda.setupTime ||
            !agenda.preferredSetupTimeslotId ||
            !agenda.breakupTime
          ) {
            areValid = false;
          }
        }
      }
      return areValid;
    },
    async validateWetLabSetup() {
      this.$scrollTo("#ascForm");
      if (this.showFullForm) {
        const valids = await Promise.all(
          this.wetlabSetups.map(async (_, index) => {
            const results = Promise.all([
              this.$validator.validate("number of operating stations" + index),
              this.$validator.validate("washing/sterilisation wishes" + index),
              this.$validator.validate("specimens required" + index),
            ]);
            const areValid = (await results).every((isValid) => isValid);
            let areValidWS = true;
            if (this.wetlabSetups[0].washingSterilisation == 1) {
              const resultsWS = Promise.all([
                this.$validator.validate("number of kits to be washed" + index),
                this.$validator.validate(
                  "number of kits to be sterilized" + index
                ),
              ]);
              areValidWS = (await resultsWS).every((isValid) => isValid);
            }
            let areValidCARM = true;
            // if (this.wetlabSetups[0].CArms >= 1) {
            //   const resultsCARM = Promise.all([
            //     this.$validator.validate("external C-arms for days"),
            //   ]);
            //   areValidCARM = (await resultsCARM).every((isValid) => isValid);
            // }
            if (areValid && areValidWS && areValidCARM) {
              return true;
            } else {
              return false;
            }
          })
        );
        return valids.every((isValid) => isValid);
      } else {
        const results = Promise.all([
          this.$validator.validate("number of operating stations"),
          this.$validator.validate("specimens required"),
        ]);
        const areValid = (await results).every((isValid) => isValid);
        let areValidCARM = true;
        if (this.wetlabSetups[0].CArms >= 1) {
          const resultsCARM = Promise.all([
            this.$validator.validate("external C-arms for days"),
          ]);
          areValidCARM = (await resultsCARM).every((isValid) => isValid);
        }
        if (areValid && areValidCARM) {
          return true;
        } else {
          return false;
        }
      }
    },
    async validateSpecimens() {
      this.$scrollTo("#ascForm");
      if (this.showFullForm) {
        if (this.wetlabSetups[0].specimens.toLowerCase().trim() == "yes") {
          const results = Promise.all([
            this.$validator.validate("type of specimen"),
            //this.$validator.validate("number of specimens"),
            this.$validator.validate("specimen approach"),
            this.$validator.validate("position of specimen"),
            this.$validator.validate("CT scanned prior"),
            this.$validator.validate("pre-fractured"),
            this.$validator.validate("specimen procedures"),
            this.$validator.validate("specimen importance"),
            this.$validator.validate("specimen benefits"),
            this.$validator.validate("learning objectives"),
            this.$validator.validate("new or existing course"),
            this.$validator.validate("course endorsed or supported"),
            this.$validator.validate("anatomical regions/structures"),
          ]);
          const areValid = (await results).every((isValid) => isValid);
          let areValidF4L = true;
          if (this.specimens.specimenTypeId === 1) {
            const resultsF4l = Promise.all([
              this.$validator.validate("F4L anatomical region description"),
            ]);
            areValidF4L = (await resultsF4l).every((isValid) => isValid);
          }
          let areValidPosOther = true;
          if (this.specimens.specimenPositionId === 5) {
            const resultsPosOther = Promise.all([
              this.$validator.validate("other position description"),
            ]);
            //CHECK OP FILE UPLOAD!!!!!!
            areValidPosOther = (await resultsPosOther).every(
              (isValid) => isValid
            );
          }
          if (areValid && areValidF4L && areValidPosOther) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        if (this.wetlabSetups[0].specimens.toLowerCase().trim() == "yes") {
          const results = Promise.all([
            this.$validator.validate("type of specimen"),
            //this.$validator.validate("number of specimens"),
            this.$validator.validate("CT scanned prior"),
            this.$validator.validate("pre-fractured"),
            this.$validator.validate("specimen procedures"),
            this.$validator.validate("specimen importance"),
            this.$validator.validate("specimen benefits"),
            this.$validator.validate("learning objectives"),
            this.$validator.validate("new or existing course"),
            this.$validator.validate("course endorsed or supported"),
          ]);
          const areValid = (await results).every((isValid) => isValid);
          let areValidF4L = true;
          if (this.specimens.specimenTypeId === 1) {
            const resultsF4l = Promise.all([
              this.$validator.validate("F4L anatomical region description"),
            ]);
            areValidF4L = (await resultsF4l).every((isValid) => isValid);
          }
          if (areValid && areValidF4L) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },
    async validateSpaces() {
      this.$scrollTo("#ascForm");
      if (this.showFullForm) {
        const results = Promise.all([
          this.$validator.validate("amount of flipcharts/white boards"),
          this.$validator.validate("display-screen"),
        ]);
        const areValid = (await results).every((isValid) => isValid);

        let areValidBeijing = true;
        if (this.conferenceroom.locations.includes(1)) {
          const resultsBeijing = Promise.all([
            this.$validator.validate("Beijing setup"),
          ]);
          areValidBeijing = (await resultsBeijing).every((isValid) => isValid);
          if (this.conferenceroom.setupBeijing.setupId == 8) {
            const resultsBeijingOther = Promise.all([
              this.$validator.validate("Beijing other setup"),
            ]);
            areValidBeijing = (await resultsBeijingOther).every(
              (isValid) => isValid
            );
          }
        }

        let areValidMumbai = true;
        if (this.conferenceroom.locations.includes(2)) {
          const resultsMumbai = Promise.all([
            this.$validator.validate("Mumbai setup"),
          ]);
          areValidMumbai = (await resultsMumbai).every((isValid) => isValid);
          if (this.conferenceroom.setupMumbai.setupId == 8) {
            const resultsMumbaiOther = Promise.all([
              this.$validator.validate("Mumbai other setup"),
            ]);
            areValidMumbai = (await resultsMumbaiOther).every(
              (isValid) => isValid
            );
          }
        }

        let areValidNairobi = true;
        if (this.conferenceroom.locations.includes(3)) {
          const resultsNairobi = Promise.all([
            this.$validator.validate("Nairobi setup"),
          ]);
          areValidNairobi = (await resultsNairobi).every((isValid) => isValid);
          if (this.conferenceroom.setupNairobi.setupId == 8) {
            const resultsNairobiOther = Promise.all([
              this.$validator.validate("Nairobi other setup"),
            ]);
            areValidNairobi = (await resultsNairobiOther).every(
              (isValid) => isValid
            );
          }
        }

        let areValidAddRooms = true;
        let i = 0;
        for (let day of this.conferenceroom.additionalRoomsPerDay) {
          i++;
          if (day.additionalRoomsSelection.length) {
            this.$validator.validate("additional rooms time frames day " + i);
            if (!day.timeFramesDescription) {
              areValidAddRooms = false;
            }
          }
        }

        if (
          areValid &&
          areValidBeijing &&
          areValidMumbai &&
          areValidNairobi &&
          areValidAddRooms
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        let areValidAddRooms = true;
        let i = 0;
        for (let day of this.conferenceroom.additionalRoomsPerDay) {
          i++;
          if (day.additionalRoomsSelection.length) {
            this.$validator.validate("additional rooms time frames day " + i);
            if (!day.timeFramesDescription) {
              areValidAddRooms = false;
            }
          }
        }
        return areValidAddRooms;
      }
    },
    async validateCatering() {
      this.$scrollTo("#ascForm");
      return true;
    },
    changeEventStartDate() {
      this.changeEventLength();
      /*
      //console.log("changeEventStartDate()", this.events.eventDate);
      for (let day = 0; day < this.agendas.length; day++) {
        //console.log("dag");
        let currentDate = "";
        if (this.events.eventDate !== null) {
          currentDate = moment(this.events.eventDate).add(day, "days");
        } else {
          //console.log("null");
          currentDate = moment("2021-02-02").add(day, "days");
          //console.log(currentDate.format("YYYY-MM-DD"));
        }
        //console.log("currentDate", currentDate);
        this.agendas[day].eventDayDate = currentDate.format("YYYY-MM-DD");
      }
      */
    },
    changeEventLength() {
      if (this.events.eventDate && this.events.numberOfDays) {
        const startDate = moment(this.events.eventDate);
        const newEventLength = this.events.numberOfDays;
        const currentEventLength = this.agendas.length;

        ////nieuwe manier
        this.timeSchedulesTabIndex = 0;
        this.wetLabTabIndex = 0;
        this.wetLabSetupTabIndex = 0;
        this.additionalRoomsTabIndex = 0;
        this.cateringTabIndex = 0;

        this.agendasBackup = this.agendas;
        this.agendas = [];

        this.wetlabAgendasBackup = this.wetlabAgendas;
        this.wetlabAgendas = [];

        this.wetlabSetupsBackup = this.wetlabSetups;
        this.wetlabSetups = [];

        this.conferenceroomAdditionalRoomsPerDayBackup =
          this.conferenceroom.additionalRoomsPerDay;
        this.conferenceroom.additionalRoomsPerDay = [];

        this.cateringBackup = this.catering;
        this.catering = [];

        for (let day = 0; day < newEventLength; day++) {
          const currentDate = moment(startDate).add(day, "days");
          const currentDateFormatted = currentDate.format("YYYY-MM-DD");

          let newDay = {};
          if (this.agendasBackup[day] == null) {
            newDay = {
              id: null,
              eventId: null,
              day: day + 1,
              eventDayDate: currentDateFormatted,
              startTime: "",
              endTime: "",
              staffArriveTime: "",
              staffLeaveTime: "",
              active: 1,
            };
          } else {
            newDay = {
              id: this.agendasBackup[day].id,
              eventId: this.agendasBackup[day].eventId,
              day: day + 1,
              eventDayDate: currentDateFormatted,
              startTime: this.agendasBackup[day].startTime,
              endTime: this.agendasBackup[day].endTime,
              staffArriveTime: this.agendasBackup[day].staffArriveTime,
              staffLeaveTime: this.agendasBackup[day].staffLeaveTime,
              active: this.agendasBackup[day].active,
            };
          }
          this.agendas.push(newDay);

          let newWetLabSetupDay = {};
          if (this.wetlabSetupsBackup[day] == null) {
            newWetLabSetupDay = {
              CArms: null,
              CArmsDays: null,
              CArmsExternal: null,
              agendaId: null,
              disposables: null,
              day: day + 1,
              externalInstruments: null,
              id: null,
              instruments: null,
              ledGowns: null,
              miscellaneous: [],
              miscellaneousOther: null,
              numberOfStations: null,
              numberOfSterilisedKits: null,
              numberOfWashedKits: null,
              specimens: "no",
              washingSterilisation: 0,
            };
          } else {
            newWetLabSetupDay = {
              ...this.wetlabSetupsBackup[day],
              id: this.wetlabSetupsBackup[day].id,
              day: day + 1,
            };
          }

          this.wetlabSetups.push(newWetLabSetupDay);

          let newWetLabDay = {};
          if (this.wetlabAgendasBackup[day] == null) {
            newWetLabDay = {
              id: null,
              agendaId: null,
              startTimePreference: "",
              endTimePreference: "",
              setupTime: "",
              preferredSetupTimeslotId: null,
              breakupTime: "",
              day: day + 1,
              wetLabNeededThisDay: true,
            };
          } else {
            newWetLabDay = {
              id: this.wetlabAgendasBackup[day].id,
              agendaId: this.wetlabAgendasBackup[day].agendaId,
              startTimePreference:
                this.wetlabAgendasBackup[day].startTimePreference,
              endTimePreference:
                this.wetlabAgendasBackup[day].endTimePreference,
              setupTime: this.wetlabAgendasBackup[day].setupTime,
              preferredSetupTimeslotId:
                this.wetlabAgendasBackup[day].preferredSetupTimeslotId,
              breakupTime: this.wetlabAgendasBackup[day].breakupTime,
              day: day + 1,
              wetLabNeededThisDay:
                this.wetlabAgendasBackup[day].wetLabNeededThisDay,
            };
          }
          this.wetlabAgendas.push(newWetLabDay);

          let newAdditionalRoomDay = {};
          if (this.conferenceroomAdditionalRoomsPerDayBackup[day] == null) {
            newAdditionalRoomDay = {
              additionalRoomsSelection: [],
              timeFramesDescription: "",
              day: day + 1,
            };
          } else {
            newAdditionalRoomDay = {
              additionalRoomsSelection:
                this.conferenceroomAdditionalRoomsPerDayBackup[day]
                  .additionalRoomsSelection,
              timeFramesDescription:
                this.conferenceroomAdditionalRoomsPerDayBackup[day]
                  .timeFramesDescription,
              day: day + 1,
            };
          }
          this.conferenceroom.additionalRoomsPerDay.push(newAdditionalRoomDay);

          let newCateringday = {};
          if (this.cateringBackup[day] == null) {
            newCateringday = {
              cateringHasWelcomeReception: false,
              cateringWelcomeReceptionFromTime: "9:00",
              cateringWelcomeReceptionTillTime: "9:45",
              cateringHasBreak1: false,
              cateringBreak1FromTime: "11:00",
              cateringBreak1TillTime: "11:15",
              break1: [],
              cateringHasBreak2: false,
              cateringBreak2FromTime: "14:00",
              cateringBreak2TillTime: "14:15",
              break2: [],
              cateringHasBreak3: false,
              cateringBreak3FromTime: "16:00",
              cateringBreak3TillTime: "16:15",
              break3: [],
              cateringHasLunch: false,
              cateringLunchFromTime: "12:00",
              cateringLunchTillTime: "12:30",
              lunch: [],
              lunchAddOn: [],
              dessert: [],
              drink: [],
              snack: [],
              dietaryRequest: "",
              day: day + 1,
            };
          } else {
            newCateringday = {
              cateringHasWelcomeReception:
                this.cateringBackup[day].cateringHasWelcomeReception,
              cateringWelcomeReceptionFromTime:
                this.cateringBackup[day].cateringWelcomeReceptionFromTime,
              cateringWelcomeReceptionTillTime:
                this.cateringBackup[day].cateringWelcomeReceptionTillTime,
              cateringHasBreak1: this.cateringBackup[day].cateringHasBreak1,
              cateringBreak1FromTime:
                this.cateringBackup[day].cateringBreak1FromTime,
              cateringBreak1TillTime:
                this.cateringBackup[day].cateringBreak1TillTime,
              break1: this.cateringBackup[day].break1,
              cateringHasBreak2: this.cateringBackup[day].cateringHasBreak2,
              cateringBreak2FromTime:
                this.cateringBackup[day].cateringBreak2FromTime,
              cateringBreak2TillTime:
                this.cateringBackup[day].cateringBreak2TillTime,
              break2: this.cateringBackup[day].break2,
              cateringHasBreak3: this.cateringBackup[day].cateringHasBreak3,
              cateringBreak3FromTime:
                this.cateringBackup[day].cateringBreak3FromTime,
              cateringBreak3TillTime:
                this.cateringBackup[day].cateringBreak3TillTime,
              break3: this.cateringBackup[day].break3,
              cateringHasLunch: this.cateringBackup[day].cateringHasLunch,
              cateringLunchFromTime:
                this.cateringBackup[day].cateringLunchFromTime,
              cateringLunchTillTime:
                this.cateringBackup[day].cateringLunchTillTime,
              lunch: this.cateringBackup[day].lunch,
              lunchAddOn: this.cateringBackup[day].lunchAddOn,
              dessert: this.cateringBackup[day].dessert,
              drink: this.cateringBackup[day].drink,
              snack: this.cateringBackup[day].snack,
              dietaryRequest: this.cateringBackup[day].dietaryRequest,
              day: day + 1,
            };
          }
          this.catering.push(newCateringday);
        }
      }
    },
    changeWetlabReqOnTab(tab) {
      if (this.events.wetlabRequired.toLowerCase().trim() == "yes") {
        switch (tab) {
          case "specimens":
            this.$refs.ascForm.changeTab(6, 4);
            this.timeSchedulesTabIndex = 0;
            this.wetLabTabIndex = 0;
            this.wetLabSetupTabIndex = 0;
            this.additionalRoomsTabIndex = 0;
            this.cateringTabIndex = 0;
            break;
          case "wet lab setup":
            this.$refs.ascForm.changeTab(5, 4);
            this.timeSchedulesTabIndex = 0;
            this.wetLabTabIndex = 0;
            this.wetLabSetupTabIndex = 0;
            this.additionalRoomsTabIndex = 0;
            this.cateringTabIndex = 0;
            break;
        }
      }
    },
    showWetLabAgendaCopyTimesBtn(index) {
      if (index > 0) {
        for (let agenda of this.wetlabAgendas) {
          if (!agenda.wetLabNeededThisDay) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    resetTabIndex() {
      this.timeSchedulesTabIndex = 0;
      this.wetLabTabIndex = 0;
      this.wetLabSetupTabIndex = 0;
      this.additionalRoomsTabIndex = 0;
      this.cateringTabIndex = 0;
    },
    sendFileDropzone(file, xhr, formData) {
      if (this.$route.query.eventId) {
        formData.append("eventId", this.$route.query.eventId);
      } else if (this.events.id) {
        formData.append("eventId", this.events.id);
      }
    },
    removeFileAgenda(file) {
      const formData = new FormData();
      formData.append("eventId", this.events.id);
      formData.append("file", null);
      this.axios.post(this.apiBaseUrl + "uploadDraftAgenda", formData).then(
        function (result) {
          console.log(result);
        },
        function (error) {
          console.log(error);
        }
      );
    },
    removeFileAttendees(file) {
      const formData = new FormData();
      formData.append("eventId", this.events.id);
      formData.append("file", null);
      this.axios.post(this.apiBaseUrl + "uploadAttendeeList", formData).then(
        function (result) {
          console.log(result);
        },
        function (error) {
          console.log(error);
        }
      );
    },
    removeFileOtherPosition(file) {
      const formData = new FormData();
      formData.append("eventId", this.events.id);
      formData.append("file", null);
      this.axios
        .post(this.apiBaseUrl + "uploadSpecimenPosition", formData)
        .then(
          function (result) {
            console.log(result);
          },
          function (error) {
            console.log(error);
          }
        );
    },
    wetlabSetupTabChange(index) {
      this.wetLabSetupTabIndex = index;
      if (!this.wetlabSetups[index].miscellaneous) {
        return;
      }
      this.dropdownOptions.wetlabMiscellaneousCheckboxes.forEach(
        (checkOption, optIndex) => {
          this.dropdownOptions.wetlabMiscellaneousCheckboxes[
            optIndex
          ].value = false;
        }
      );
      this.wetlabSetups[index].miscellaneous.forEach((option) => {
        this.dropdownOptions.wetlabMiscellaneousCheckboxes.forEach(
          (checkOption, optIndex) => {
            if (checkOption.id === option) {
              this.dropdownOptions.wetlabMiscellaneousCheckboxes[
                optIndex
              ].value = true;
            }
          }
        );
      });
      //this.dropdownOptions.wetlabMiscellaneousCheckboxes =
    },
  },
  created() {
    let urlParams = "";
    if (this.$route.query.eventId) {
      urlParams = "?eventId=" + this.$route.query.eventId;
    } else if (this.$route.query.moodleUI) {
      urlParams = "?moodleUI=" + this.$route.query.moodleUI;
    }
    let token = "";
    if (this.$route.query.token) {
      token = "&token=" + this.$route.query.token;
    }
    urlParams = urlParams + token;
    //bestaande form data "https://asc-dynform-api-acc.lwdev.nl/getFormData?eventId=296"
    //nieuwe form data https://asc-dynform-api-acc.lwdev.nl/getFormData?moodleUI=264
    this.loading = true;
    if (urlParams) {
      this.axios
        .get(this.apiBaseUrl + "getFormData" + urlParams)
        .then((response) => {
          let res = response.data.data;

          this.conferenceroom = res.conferenceroom;
          /*
          this.conferenceroom.additionalRoomsPerDay = [
            {
              additionalRoomsSelection: [],
              day: 1,
              timeFramesDescription: ""
            }
          ];
          */
          //conferenceroom
          this.showFullForm = res.showFullForm;
          this.wetlabAgendas = res.wetlabAgendas;
          this.agendas = res.agendas;

          this.appUser = res.appUser;
          this.attendees = res.attendees;
          this.catering = res.catering;

          this.dropdownOptions = res.dropdownOptions;
          this.events = res.events;
          this.specimens = res.specimens;

          if (res.wetlabSetup[0]) {
            this.wetlabSetups = res.wetlabSetup;
          } else {
            this.wetlabSetups = [res.wetlabSetup];
          }

          this.wetlabSetupTabChange(0);

          //
          //this.changeEventLength();
          //this.changeEventStartDate();
          //

          if (this.conferenceroom.length === 0) {
            this.conferenceroom = {
              numberOfFlipcharts: null, //integer (is null when no data is available)
              displayScreen: true, //boolean true/false (can also be 1/0)
              welcomeDesk: true, //boolean true/false (can also be 1/0)
              locations: [], //array with id's
              combineRooms: "", //text-boolean "yes"/"no" (must be lowercase)
              setupBeijing: {
                //setupBeijing should be an empty object when no data is available.
                setupId: null, //integer
                other: "", //string
              },
              setupMumbai: {
                //setupMumbai should be an empty object when no data is available.
                setupId: null, //integer
                other: "", //string
              },
              setupNairobi: {
                //setupNairobi should be an empty object when no data is available.
                setupId: null, //integer
                other: "", //string
              },
              additionalRoomsPerDay: [
                //additionalRoomsPerDay should be an empty array when no data is available.
                //Incase data is available, an object as shown below is needed for each day
                {
                  additionalRoomsSelection: [], //array with id's
                  day: 1,
                  timeFramesDescription: "", //string
                },
              ],
            };
            this.fillconferenceRoomsArray();
          }

          this.$validator.pause();
          this.$nextTick(() => {
            //agenda file dropzone
            if (res.events.draftAgenda && res.events.draftAgendaLink) {
              const file = {
                size: 500,
                name: res.events.draftAgenda,
                type: "application/octet-stream",
              };
              const url = res.events.draftAgendaLink;
              this.$nextTick(() => {
                this.$refs.agendaDropzone.manuallyAddFile(file, url);
              });
            }

            //attendeeslist dropzone
            if (res.attendees.attendeeList && res.attendees.attendeeListLink) {
              const file2 = {
                size: 500,
                name: res.attendees.attendeeList,
                type: "application/octet-stream",
              };
              const url2 = res.attendees.attendeeListLink;
              this.$nextTick(() => {
                this.$refs.attendeesDropzone.manuallyAddFile(file2, url2);
              });
            }

            //other positopn dropzone (NOG NIET IN CALL)
            if (res.specimens.positionFile && res.specimens.positionOtherLink) {
              const file3 = {
                size: 500,
                name: res.specimens.positionFile,
                type: "application/octet-stream",
              };
              const url3 = res.specimens.positionOtherLink;

              this.$nextTick(() => {
                this.$refs.otherPositionDiagramDropzone.manuallyAddFile(
                  file3,
                  url3
                );
              });
            }

            this.$validator.errors.clear();
            this.$validator.fields.items.forEach((field) => field.reset());
            this.$validator.fields.items.forEach((field) =>
              this.errors.remove(field)
            );
            this.$validator.resume();
            this.loading = false;
          });

          this.loading = false;

          if (
            this.agendas.length !== this.events.numberOfDays ||
            this.wetlabAgendas.length !== this.events.numberOfDays ||
            this.conferenceroom.additionalRoomsPerDay.length !==
              this.events.numberOfDays ||
            this.catering.length !== this.events.numberOfDays
          ) {
            this.changeEventLength();
          }

          /*
          this.wetlabAgendas = [];
          this.conferenceroom.additionalRoomsPerDay = [];
          this.catering = [];
          */

          //this.changeEventLength();
          ////TIJDELIJK om this.specimens.anatomicalRegionFF array goed te vullen
          //this.fillSpecimensAnatomicalRegionFFArray();
        });
    }
  },
};
</script>
